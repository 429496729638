import { ITimelineSettings } from 'components/Timelines/types'
import {
  CONTRACT_STATUS,
  CONTRACT_STATUS_NAME
} from 'features/ContractDetails/constants'

export const systemContractTimelinesSettings: ITimelineSettings<
  typeof CONTRACT_STATUS
>[] = [
  {
    key: CONTRACT_STATUS.CONTRACTING,
    title: CONTRACT_STATUS_NAME[CONTRACT_STATUS.CONTRACTING],
    diffDays: 30
  },
  {
    key: CONTRACT_STATUS.REDLINING,
    title: CONTRACT_STATUS_NAME[CONTRACT_STATUS.REDLINING],
    diffDays: 10
  },
  {
    key: CONTRACT_STATUS.OUT_FOR_SIGNATURE,
    title: CONTRACT_STATUS_NAME[CONTRACT_STATUS.OUT_FOR_SIGNATURE],
    diffDays: 10
  }
]
