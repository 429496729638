import { BTN_TXT } from '../../../../../../../../constants'
import { DISPUTE_STATUSES } from '../types'
import { MODAL_TYPES } from './types'

export const POPUP_CONFIGS = {
  [MODAL_TYPES.EXCLUDE_FORM_REBATE]: {
    title: 'Exclude from rebate?',
    content:
      'Please provide the reason for excluding the invoice(s) from calculations..',
    btnText: BTN_TXT.EXCLUDE
  },
  [MODAL_TYPES.DEFER_TO_NEXT_QUARTER]: {
    title: 'Defer to next quarter?',
    content:
      'Please provide the reason for deferring the invoice(s) from calculations.',
    btnText: BTN_TXT.DEFER
  },
  [MODAL_TYPES.DECLINE_DISPUTE]: {
    title: 'Decline dispute?',
    content: 'Please, provide the reason for declining the dispute.',
    btnText: BTN_TXT.DECLINE
  }
} as const

export const DEFAULT_CONFIG = {
  title: '',
  btnText: '',
  content: ''
}

export const MODAL_TYPE_TO_DISPUTE = {
  [MODAL_TYPES.EXCLUDE_FORM_REBATE]: DISPUTE_STATUSES.EXCLUDED,
  [MODAL_TYPES.DEFER_TO_NEXT_QUARTER]: DISPUTE_STATUSES.DEFERRED
} as const
