import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Table } from '../../../../components/Table'
import { columns } from './constants'
import { isTableHasParams } from '../../../../helper/common'
import { useFinancialsContext } from '../../Providers/FinancialsProvider'
import { getUser } from '../../../../redux/store/user/getters'
import { useFinancialsAbility } from '../../Providers/FinancialsAbilityProvider'
import { DEFAULT_PAGE } from '../../../../components/Table/constants'
import { ACTIONS, SUBJECTS } from '../../Abilities'
import {
  TRebatesTableFilters,
  TRebatesTableFunc,
  TRebatesTableType
} from './types'
import './styles.scss'

export const RebatesTable = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [filters, setFilters] = useState<TRebatesTableFilters>({})
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const {
    state: { totalNumber, tableData, sortParams, firstLoad, period },
    actions: { getTableData }
  } = useFinancialsContext()

  const user = useSelector(getUser)
  const ability = useFinancialsAbility()

  useEffect(() => {
    getTableData({ ...sortParams, page: pageInfo })
    setSelectedRowKeys([])
  }, [period])

  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters,
      page
    })
    setPageInfo(page)
  }

  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      filters,
      page
    })
    setPageInfo(page)
  }

  const handleSelectRow = (selectedRowKeys) =>
    setSelectedRowKeys(selectedRowKeys)

  const applyFilters: TRebatesTableFunc = (field, appliedFilters) => {
    const newFilters = { ...filters, [field]: appliedFilters }
    const newPageInfo = { ...pageInfo, pageNumber: 1 }
    setFilters(newFilters)
    setPageInfo(newPageInfo)
    getTableData({ ...sortParams, filters: newFilters, page: newPageInfo })
  }

  return (
    <>
      {firstLoad ? null : (
        <Table<TRebatesTableType>
          dataSource={tableData}
          columns={columns({
            applyFilters,
            filters,
            user
          })}
          className="financials-table"
          onChange={handleTableChange}
          hasSearchOrFilters={isTableHasParams<TRebatesTableFilters>(
            '',
            filters
          )}
          onChangePage={handleChangePageSize}
          pageSize={pageInfo.pageSize}
          pagination={{
            pageSize: pageInfo.pageSize,
            current: pageInfo.pageNumber,
            total: totalNumber
          }}
          rowSelection={
            ability.can(ACTIONS.SELECT, SUBJECTS.REBATE)
              ? {
                  selectedRowKeys,
                  onChange: handleSelectRow
                }
              : undefined
          }
        />
      )}
    </>
  )
}
