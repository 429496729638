import Fetcher from '../../../../../../../services/Fetcher'
import {
  API_DICTIONARY,
  HTTP_METHODS,
  ResponseWithPagination
} from '../../../../../../../constants'
import { InvoicesTableRecord, TRebatesTableRequest } from './types'

const fetcher = new Fetcher({})

export const getInvoicesTableData: TRebatesTableRequest = async (id, data) =>
  fetcher.request<unknown, ResponseWithPagination<InvoicesTableRecord>>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.REBATES_DASHBOARD}${id}/${API_DICTIONARY.INVOICES}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const getLedByOptions = async (id: string) =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.REBATES_DASHBOARD}${id}/${API_DICTIONARY.INVOICES}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.LED_BY}`,
    method: HTTP_METHODS.GET
  })

export const getFacilityReviewStatusOptions = async () =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.REBATES_DASHBOARD}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.FACILITY_REVIEW_STATUS}`,
    method: HTTP_METHODS.GET
  })

export const getDisputeTypeOptions = async (id: string) =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.REBATES_DASHBOARD}${id}/${API_DICTIONARY.INVOICES}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.DISPUTE_TYPE}`,
    method: HTTP_METHODS.GET
  })

export const getContractCategoryOptions = async (id: string) =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.REBATES_DASHBOARD}${id}/${API_DICTIONARY.INVOICES}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.CONTRACT_CATEGORY}`,
    method: HTTP_METHODS.GET
  })
