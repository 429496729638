import { ReactComponent as SettingsIcon } from 'assets/svg/Settings.svg'
import { ReactComponent as DashboardIcon } from 'assets/svg/Dashboard.svg'
import { ReactComponent as ContractsIcon } from 'assets/svg/Contracts.svg'
import { ReactComponent as BiddingIcon } from 'assets/svg/Bidding.svg'
import { ReactComponent as VendorsIcon } from 'assets/svg/Vendors24.svg'
import { ReactComponent as ClinicalReview20 } from 'assets/svg/ClinicalReview20.svg'
import { ReactComponent as MessageIcon } from 'assets/svg/Message.svg'
import { ReactComponent as CalendarIcon } from 'assets/svg/Calendar.svg'
import { ReactComponent as Finance24 } from 'assets/svg/Finance24.svg'
import { ReactComponent as ProductRequests24 } from 'assets/svg/ProductRequests24.svg'
import { ReactComponent as Compliant24 } from 'assets/svg/Compliant24.svg'
import { ReactComponent as PurchaseOrdersIcon } from 'assets/svg/PurchaseOrders.svg'
import { ReactComponent as CheckInsIcon } from 'assets/svg/CheckIns.svg'
import { routes } from 'router/Config/config.routes'
import { TSortOrder } from './types'

type TSortKey = ValueOfObject<typeof ROLES_NAMES>

export const WEB_STORAGE = {
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  PHONE_NUMBER: 'phone_number',
  EMAIL: 'email',
  LOCAL_TOKEN: 'local_token',
  PASSWORD: 'password',
  LAST_PAGE: 'last_page',
  CALENDAR_VIEW: 'calendar_view'
} as const

export const JUSTIFY = {
  START: 'start',
  END: 'end',
  SPACE_BETWEEN: 'space-between'
} as const

export const USER_STATUSES = {
  ACTIVE: 'Active',
  INVITED: 'Invited',
  ARCHIVED: 'Archived'
} as const

export const CONTRACT_STATUSES = {
  ACTIVE: 'Active',
  CONTRACTING: 'Contracting',
  OUT_FOR_SIGNATURE: 'Out for signature',
  EXECUTED: 'Executed',
  EXPIRED: 'Expired',
  TERMINATED: 'Terminated',
  DECLINE: 'Decline',
  PENDING_FOR_START: 'Executed'
} as const

export const HOSPITAL_STATUSES = {
  ACTIVE: 'Active',
  ARCHIVED: 'Archived'
} as const

export const HEALTH_SYSTEM_STATUSES = {
  ACTIVE: 'Active',
  ARCHIVED: 'Archived'
} as const

export const ROLES_NAMES = {
  community_president: 'Community President',
  community_vice_president: 'Vice President',
  community_influencer: 'Influencer',
  community_contract_steward: 'Contract Steward',
  community_stakeholder: 'Stakeholder',
  community_legal: 'Legal',
  community_analyst: 'Analyst',
  community_hospital_admin: 'Hospital Admin',
  vendor_contract_admin: 'Vendor Contract Admin',
  vendor_legal: 'Vendor Legal',
  vendor_analyst: 'Vendor Analyst',
  vendor_sales_rep: 'Vendor Sales Rep',
  vendor_contract_steward: 'Vendor Contract Steward',
  vendor_finance_officer: 'Vendor Finance Officer',
  community_rca: 'Revenue Cycle Advisor',
  commons_legal: 'Commons Legal',
  commons_analyst: 'Commons Analyst',
  commons_stakeholder: 'Commons Stakeholder',
  commons_vice_president: 'Commons Vice President',
  commons_clinical_coordinator: 'Commons Clinical Coordinator',
  commons_contract_steward: 'Commons Contract Steward'
} as const

export const USER_STATUSES_OPTIONS = [
  {
    label: 'Active',
    value: 'Active'
  },
  {
    label: 'Invited',
    value: 'Invited'
  },
  {
    label: 'Archived',
    value: 'Archived'
  }
]
export const TRIAL_APPROVAL_STATUSES_OPTIONS = [
  {
    label: 'Approved',
    value: 'approved'
  },
  {
    label: 'Rejected',
    value: 'rejected'
  },
  {
    label: 'No response',
    value: 'no response'
  }
]

export const CONTRACT_STATUSES_OPTIONS = [
  {
    label: 'Contracting',
    value: 'contracting'
  },
  {
    label: 'Redlining',
    value: 'redlining'
  },
  {
    label: 'Out for signature',
    value: 'out_for_signature'
  },
  {
    label: 'Executed',
    value: 'pending_for_start'
  },
  {
    label: 'Active',
    value: 'active'
  },
  {
    label: 'Expired',
    value: 'expired'
  },
  {
    label: 'Terminated',
    value: 'terminated'
  },
  {
    label: 'Declined',
    value: 'declined'
  }
]

export const HOSPITAL_STATUSES_OPTIONS = [
  {
    label: 'Active',
    value: 'Active'
  },
  {
    label: 'Archived',
    value: 'Archived'
  }
]

export const COMMUNITY_ROLES_OPTIONS = [
  {
    label: 'Community President',
    value: 'community_president'
  },
  {
    label: 'Vice President',
    value: 'community_vice_president'
  },
  {
    label: 'Influencer',
    value: 'community_influencer'
  },
  {
    label: 'Contract Steward',
    value: 'community_contract_steward'
  },
  {
    label: 'Stakeholder',
    value: 'community_stakeholder'
  },
  {
    label: 'Legal',
    value: 'community_legal'
  },
  {
    label: 'Analyst',
    value: 'community_analyst'
  },
  {
    label: 'Hospital Admin',
    value: 'community_hospital_admin'
  },
  {
    label: 'Revenue Cycle Advisor',
    value: 'community_rca'
  }
]

export const VENDOR_ROLES_OPTIONS = [
  {
    label: 'Contract Admin',
    value: 'vendor_contract_admin'
  },
  {
    label: 'Legal',
    value: 'vendor_legal'
  },
  {
    label: 'Analyst',
    value: 'vendor_analyst'
  },
  {
    label: 'Sales Rep',
    value: 'vendor_sales_rep'
  },
  {
    label: 'Contract Steward',
    value: 'vendor_contract_steward'
  },
  {
    label: 'Finance Officer',
    value: 'vendor_finance_officer'
  }
]

export const COMMONS_ROLES_OPTIONS = [
  {
    label: 'Commons Legal',
    value: 'commons_legal'
  },
  {
    label: 'Commons Analyst',
    value: 'commons_analyst'
  },
  {
    label: 'Commons Stakeholder',
    value: 'commons_stakeholder'
  },
  {
    label: 'Commons Vice President',
    value: 'commons_vice_president'
  },
  {
    label: 'Commons Clinical Coordinator',
    value: 'commons_clinical_coordinator'
  },
  {
    label: 'Commons Contract Steward',
    value: 'commons_contract_steward'
  }
]

export const EMAIL_PATTERN = /^.+[@](\w+[.]\w+).{1,}$/

export const TABS_LABELS = {
  SETTINGS: 'Settings'
}

export const DEFAULT_SEARCH_FILTER_PARAMS = {
  limit: 50
}

export const PORTAL_TABS_TOP = [
  {
    Icon: DashboardIcon,
    label: 'Dashboard',
    hrefTo: routes.dashboard
  },
  {
    Icon: Compliant24,
    label: 'Compliance',
    hrefTo: routes.compliance
  },
  {
    Icon: Compliant24,
    label: 'Market Insights',
    hrefTo: routes.marketInsights
  },
  {
    Icon: Finance24,
    label: 'Financials',
    hrefTo: routes.financials
  },
  {
    Icon: ContractsIcon,
    label: 'Contracts',
    hrefTo: routes.contracts
  },
  {
    Icon: BiddingIcon,
    label: 'Bidding',
    hrefTo: routes.bidding
  },
  {
    Icon: ProductRequests24,
    label: 'Product Requests',
    hrefTo: routes.productRequests
  },
  {
    Icon: PurchaseOrdersIcon,
    label: 'Invoice data',
    hrefTo: routes.invoiceData
  },
  {
    Icon: ClinicalReview20,
    label: 'Clinical Review',
    hrefTo: routes.clinicalReview
  },
  {
    Icon: VendorsIcon,
    label: 'My Community',
    hrefTo: routes.myCommunity
  },
  {
    Icon: MessageIcon,
    label: 'Chat',
    hrefTo: routes.chat
  },
  {
    Icon: CalendarIcon,
    label: 'Calendar',
    hrefTo: routes.calendar
  },
  {
    Icon: CheckInsIcon,
    label: 'Check-Ins',
    hrefTo: routes.checkIns
  }
]

export const PORTAL_TABS_BOTTOM = [
  {
    Icon: SettingsIcon,
    label: 'Settings',
    hrefTo: routes.settings
  }
]

export const ROLES_OPTIONS_ALL = [
  {
    label: 'Community President',
    value: 'community_president'
  },
  {
    label: 'Vice President',
    value: 'community_vice_president'
  },
  {
    label: 'Contract Steward',
    value: 'community_contract_steward'
  },
  {
    label: 'Legal',
    value: 'community_legal'
  },
  {
    label: 'Analyst',
    value: 'community_analyst'
  },
  {
    label: 'Hospital Admin',
    value: 'community_hospital_admin'
  },
  {
    label: 'Stakeholder',
    value: 'community_stakeholder'
  },
  {
    label: 'Influencer',
    value: 'community_influencer'
  },
  {
    label: 'Contract Admin',
    value: 'vendor_contract_admin'
  },
  {
    label: 'Legal',
    value: 'vendor_legal'
  },
  {
    label: 'Analyst',
    value: 'vendor_analyst'
  },
  {
    label: 'Sales Representative',
    value: 'vendor_sales_rep'
  },
  {
    label: 'Contract Steward',
    value: 'vendor_contract_steward'
  },
  {
    label: 'Vendor Finance Officer',
    value: 'vendor_finance_officer'
  },
  {
    label: 'Revenue Cycle Advisor',
    value: 'community_rca'
  },
  {
    label: 'Commons Legal',
    value: 'commons_legal'
  },
  {
    label: 'Commons Analyst',
    value: 'commons_analyst'
  },
  {
    label: 'Commons Stakeholder',
    value: 'commons_stakeholder'
  },
  {
    label: 'Commons Vice President',
    value: 'commons_vice_president'
  },
  {
    label: 'Commons Clinical Coordinator',
    value: 'commons_clinical_coordinator'
  },
  {
    label: 'Commons Contract Steward',
    value: 'commons_contract_steward'
  }
]
export const ROLES_OPTIONS_COMMUNITY = [
  {
    label: 'Vice President',
    value: 'community_vice_president'
  },
  {
    label: 'Contract Steward',
    value: 'community_contract_steward'
  },
  {
    label: 'Revenue Cycle Advisor',
    value: 'community_rca'
  },
  {
    label: 'Legal',
    value: 'community_legal'
  },
  {
    label: 'Analyst',
    value: 'community_analyst'
  },
  {
    label: 'Hospital Admin',
    value: 'community_hospital_admin'
  },
  {
    label: 'Stakeholder',
    value: 'community_stakeholder'
  },
  {
    label: 'Influencer',
    value: 'community_influencer'
  }
]
export const ROLES_OPTIONS_VICE = [
  {
    label: 'Contract Steward',
    value: 'community_contract_steward'
  },
  {
    label: 'Revenue Cycle Advisor',
    value: 'community_rca'
  },
  {
    label: 'Legal',
    value: 'community_legal'
  },
  {
    label: 'Analyst',
    value: 'community_analyst'
  },
  {
    label: 'Hospital Admin',
    value: 'community_hospital_admin'
  },
  {
    label: 'Stakeholder',
    value: 'community_stakeholder'
  },
  {
    label: 'Influencer',
    value: 'community_influencer'
  }
]
export const ROLES_OPTIONS_CA = [
  {
    label: 'Contract Steward',
    value: 'vendor_contract_steward'
  },
  {
    label: 'Legal',
    value: 'vendor_legal'
  },
  {
    label: 'Analyst',
    value: 'vendor_analyst'
  },
  {
    label: 'Sales Representative',
    value: 'vendor_sales_rep'
  },
  {
    label: 'Vendor Finance Officer',
    value: 'vendor_finance_officer'
  }
]

export const ROLES_OPTIONS_VENDOR_CS = [
  {
    label: 'Legal',
    value: 'vendor_legal'
  },
  {
    label: 'Analyst',
    value: 'vendor_analyst'
  },
  {
    label: 'Sales Representative',
    value: 'vendor_sales_rep'
  }
]
export const ROLES_OPTIONS_COMMONS_VP = [
  {
    label: 'Commons Clinical Coordinator',
    value: 'commons_clinical_coordinator'
  },
  {
    label: 'Commons Contract Steward',
    value: 'commons_contract_steward'
  },
  {
    label: 'Commons Analyst',
    value: 'commons_analyst'
  },
  {
    label: 'Commons Legal',
    value: 'commons_legal'
  },
  {
    label: 'Commons Stakeholder',
    value: 'commons_stakeholder'
  }
]
export const ROLES_OPTIONS_COMMONS_CS = [
  {
    label: 'Commons Analyst',
    value: 'commons_analyst'
  },
  {
    label: 'Commons Legal',
    value: 'commons_legal'
  },
  {
    label: 'Commons Stakeholder',
    value: 'commons_stakeholder'
  }
]
export const ROLES_OPTIONS_CS = [
  {
    label: 'Legal',
    value: 'community_legal'
  },
  {
    label: 'Analyst',
    value: 'community_analyst'
  },
  {
    label: 'Hospital Admin',
    value: 'community_hospital_admin'
  },
  {
    label: 'Stakeholder',
    value: 'community_stakeholder'
  },
  {
    label: 'Influencer',
    value: 'community_influencer'
  },
  {
    label: 'Revenue Cycle Advisor',
    value: 'community_rca'
  }
]

export const emptyStateUser = {
  first_name: '',
  last_name: '',
  phone_number: '',
  role: '',
  email: '',
  health_system: '',
  contract_categories: [],
  departments: [],
  hospital: '',
  hospitals: []
}

export const ROLES_OPTIONS_HOSPITALS = [
  {
    label: 'Stakeholder',
    value: 'community_stakeholder'
  },
  {
    label: 'Influencer',
    value: 'community_influencer'
  },
  {
    label: 'Hospital Admin',
    value: 'community_hospital_admin'
  }
]

export const EMPTY_STATES_TYPES = {
  COMPLIANCE: 'compliance',
  PROJECTS: 'projects',
  CHATS: 'chats',
  FINANCIALS: 'financials',
  PURCHASE_ORDERS: 'Purchase orders',
  PRODUCT_REQUESTS: 'Product requests'
}

export const CHAT_TYPE = {
  PRIVATE: 'private',
  GROUP: 'group',
  CHANNEL: 'channel'
} as const

export const OPTIONS_SORT_ORDER: Partial<TSortOrder<TSortKey>> = {
  [ROLES_NAMES.community_vice_president]: 2,
  [ROLES_NAMES.community_contract_steward]: 1
}

export const PRS_STATUSES_OPTIONS = [
  {
    label: 'Created',
    value: 'created'
  },
  {
    label: 'Waiting for review',
    value: 'waiting_for_review'
  },
  {
    label: 'In review',
    value: 'in_review'
  },
  {
    label: 'RCA review',
    value: 'rca_review'
  },
  {
    label: 'Survey creation',
    value: 'survey_creation'
  },
  {
    label: 'Voting in progress',
    value: 'voting_in_progress'
  },
  {
    label: 'Decision making',
    value: 'decision_making'
  },
  {
    label: 'Archived',
    value: 'archived'
  },
  {
    label: 'Declined',
    value: 'declined'
  },
  {
    label: 'Canceled',
    value: 'canceled'
  }
]
export const PRS_STATUSES_OPTIONS_FILTER = [
  {
    label: 'Waiting for review',
    value: 'waiting_for_review'
  },
  { label: 'Clinical sponsor approval', value: 'clinical_sponsor_approval' },
  {
    label: 'In review',
    value: 'in_review'
  },
  { label: 'Trial pre-approval', value: 'trial_pre_approval' },
  { label: 'Trial requested', value: 'trial_requested' },
  { label: 'Trial in progress', value: 'trial_in_progress' },
  { label: 'Revenue assessment', value: 'revenue_assessment' },
  { label: 'Survey creation', value: 'survey_creation' },
  { label: 'Voting in progress', value: 'voting_in_progress' },

  {
    label: 'Decision making',
    value: 'decision_making'
  },
  {
    label: 'Archived',
    value: 'archived'
  },
  {
    label: 'Canceled',
    value: 'canceled'
  },
  {
    label: 'Declined',
    value: 'declined'
  }
]
export const PRS_STATUSES_OPTIONS_FILTER_VENDOR = [
  {
    label: 'Created',
    value: 'created'
  },
  {
    label: 'Waiting for review',
    value: 'waiting_for_review'
  },
  { label: 'Clinical sponsor approval', value: 'clinical_sponsor_approval' },
  {
    label: 'In review',
    value: 'in_review'
  },
  { label: 'Trial pre-approval', value: 'trial_pre_approval' },
  { label: 'Trial requested', value: 'trial_requested' },
  { label: 'Trial in progress', value: 'trial_in_progress' },
  { label: 'Revenue assessment', value: 'revenue_assessment' },
  { label: 'Survey creation', value: 'survey_creation' },
  { label: 'Voting in progress', value: 'voting_in_progress' },

  {
    label: 'Decision making',
    value: 'decision_making'
  },
  {
    label: 'Archived',
    value: 'archived'
  },
  {
    label: 'Canceled',
    value: 'canceled'
  },
  {
    label: 'Declined',
    value: 'declined'
  }
]

export enum FIREBASE_MESSAGES_TYPES {
  CONTRACT_BULK_CREATION_MESSAGE_TYPE = 'contracts_bulk_creation',
  REJECTED_INVOICES_DOWNLOAD_MESSAGE_TYPE = 'rejected_invoice_report_completed',
  UPLOAD_TEM_LIST_FINISHED_MESSAGE_TYPE = 'reconciliation_process_finished',
  CONTRACT_ITEMS_FILE_DOWNLOAD_MESSAGE_TYPE = 'contract_items_file_download'
}
