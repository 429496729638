import { useSelector } from 'react-redux'
import {
  Typography,
  TYPOGRAPHY_WEIGHT
} from '../../../../../../../../components/Typography'
import { VendorActions } from '../VendorActions/VendorActions'
import { CommunityActions } from '../CommunityActions/CommunityActions'
import { useFinancialsAbility } from '../../../../../../Providers/FinancialsAbilityProvider'
import { getUser } from '../../../../../../../../redux/store/user/getters'
import { ACTIONS, SUBJECTS } from '../../../../../../Abilities'
import { InvoicesTableHeaderProps } from './types'

export const InvoicesTableHeader = ({
  totalNumber,
  pageSize,
  currentPage,
  setSelectedRowKeys,
  selectedRowKeys,
  updateData
}: InvoicesTableHeaderProps) => {
  const user = useSelector(getUser)
  const isVendor = !!user?.vendor
  const ability = useFinancialsAbility()

  return (
    <div className="row space-between align-center">
      <div className="column">
        <Typography.Body1 weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}>
          Selected {selectedRowKeys.length} of {totalNumber}
        </Typography.Body1>
        <Typography.Caption className="page-number">
          {`Page ${currentPage} of ${Math.ceil(totalNumber / pageSize)}`}
        </Typography.Caption>
      </div>
      {ability.can(ACTIONS.DISPUTE, SUBJECTS.INVOICE) && (
        <div className="row gap-8">
          {isVendor ? (
            <VendorActions
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
              updateData={updateData}
            />
          ) : (
            <CommunityActions
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
              updateData={updateData}
            />
          )}
        </div>
      )}
    </div>
  )
}
