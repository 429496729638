import './styles.scss'

import { PLACEHOLDERS, TOOLTIP } from 'constants/txt'
import { Datepicker } from 'components/Datepicker'
import { Select } from 'components/Select'
import { Button } from 'components/Button'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { Typography } from 'components/Typography'
import useTimeline, {
  UseTimelineProps
} from 'components/Timelines/hooks/useTimeline'
import { TDropdownItem } from 'components/Dropdown/types'
import { timelineDateFormat } from 'components/Timelines/constants'
import { ReactComponent as Reset16 } from 'assets/svg/Reset16.svg'
import { KeyboardEvent } from 'react'
import { Moment } from 'moment'

export type TimelineProps<TKey extends Record<string, string>> =
  UseTimelineProps<TKey> & {
    timeOptions: TDropdownItem[]
    isPrs?: boolean
    disabledDateForPrs?: Moment
  }

const Timeline = <TKey extends Record<string, string>>(
  props: TimelineProps<TKey>
) => {
  const { isPrs = false, timeOptions, ...useTimelineProps } = props

  const { state, actions } = useTimeline(useTimelineProps)

  const {
    title,
    date,
    hasChanges,
    validation,
    duration,
    timeLabel,
    disabledDate,
    disabled
  } = state

  const { updateDate, updateTime, resetState } = actions

  const onSelectInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!/^(?:\d|Backspace)$/.test(e.key)) {
      e.preventDefault()
    }
  }
  return (
    <div className="timeline">
      <Typography.Body1 className="timeline__title">{title}:</Typography.Body1>
      <Tooltip
        title={TOOLTIP.STEP_HAS_PASSED}
        overlayInnerStyle={{
          display: disabled ? 'block' : 'none',
          width: 314
        }}
        placement="top"
      >
        <div className="timeline__date timeline__item">
          <Datepicker
            className="timeline__date-picker"
            propsDate={{
              format: timelineDateFormat,
              value: date,
              status: validation?.status,
              onChange: updateDate,
              disabledDate,
              disabled
            }}
          />
        </div>
      </Tooltip>
      <Tooltip
        title={TOOLTIP.STEP_HAS_PASSED}
        overlayInnerStyle={{
          display: disabled ? 'block' : 'none',
          width: 314
        }}
        placement="top"
      >
        <div className="timeline__time timeline__item">
          <Select
            className="timeline__time-selector single-with-check"
            options={timeOptions}
            dropdownClassName="single-with-check"
            propsSelect={{
              placeholder: PLACEHOLDERS.PLEASE_SELECT,
              onInputKeyDown: onSelectInputKeyDown,
              value: timeLabel,
              status: validation?.status,
              onChange: updateTime,
              disabled,
              virtual: false
            }}
          />
        </div>
      </Tooltip>
      {(!isPrs || (isPrs && title.includes('Overall'))) && (
        <>
          <Typography.Body1 className="timeline__duration timeline__item">
            {duration}
          </Typography.Body1>
          <div className="timeline__actions timeline__item">
            {hasChanges &&
              (disabled ? null : (
                <Tooltip
                  title={TOOLTIP.RESET_TO_DEFAULT_VALUES}
                  placement="topRight"
                >
                  <Button.Icon
                    className="timeline__reset-action"
                    icon={<Reset16 />}
                    onClick={resetState}
                  />
                </Tooltip>
              ))}
          </div>
        </>
      )}
    </div>
  )
}

export default Timeline
