import EditPurchaseOrders from './forms.editPurchaseOrder'
import { SignIn } from './forms.signIn'
import { SignUp } from './forms.signUp'
import { CommunityInfo } from './forms.chapterInfo'
import { ContactUs } from './forms.contactUs'
import { ForgotPassword } from './forms.forgotPassword'
import { User } from './forms.user'
import { AddEditHospital } from './forms.addEditHospital'
import { AddEditHealthSystem } from './forms.addEditHealthSystem'
import { RFPCreation } from './forms.rfpCreation'
import { VendorInfo } from './forms.vendorInfo'
import { CreateGroupChat } from './forms.createGroupChat'
import { EditGroupChat } from './forms.editGroupChat'
import { FANumber } from './forms.2FANumber'
import { FACode } from './forms.2FACode'
import { ContactUsLanding } from './forms.contactUsLanding'
import { PRSCreation } from './forms.prsCreation'
import { PRSParticipants } from './forms.prsParticipants'
import { SurveyQuestions } from './forms.surveyQuestions'
import { CompleteProfile } from './forms.completeProfile'
import { FormsAddBusinessDetails } from './forms.addBusinessDetails'
import { AddBankAccount } from './forms.addBankAccount'
import { TForms } from './Forms.d'
import './styles.scss'

const Forms: TForms = () => 'Please, provide form component'

Forms.SignIn = SignIn
Forms.SignUp = SignUp
Forms.ChapterInfo = CommunityInfo
Forms.ContactUs = ContactUs
Forms.ForgotPassword = ForgotPassword
Forms.User = User
Forms.AddEditHospital = AddEditHospital
Forms.AddEditHealthSystem = AddEditHealthSystem
Forms.RFPCreation = RFPCreation
Forms.VendorInfo = VendorInfo
Forms.CreateGroupChat = CreateGroupChat
Forms.EditGroupChat = EditGroupChat
Forms.EditPurchaseOrders = EditPurchaseOrders
Forms.FANumber = FANumber
Forms.FACode = FACode
Forms.ContactUsLanding = ContactUsLanding
Forms.PRSCreation = PRSCreation
Forms.PRSParticipants = PRSParticipants
Forms.SurveyQuestions = SurveyQuestions
Forms.CompleteProfile = CompleteProfile
Forms.AddBusinessDetails = FormsAddBusinessDetails
Forms.AddBankAccount = AddBankAccount

export default Forms
