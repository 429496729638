import { FC, useState } from 'react'
import { subject } from '@casl/ability'
import { useAbility } from '@casl/react'

import './styles.scss'
import { Typography } from 'components/Typography'
import { Button, BUTTON_TYPES } from 'components/Button'
import { ReactComponent as Delete16 } from 'assets/svg/Delete16.svg'
import { ReactComponent as ReassignIcon } from 'assets/svg/Reassign.svg'
import { TResponsibleUser } from 'features/RFP/RFPDetails/types'
import {
  ACTIONS,
  SUBJECTS,
  AbilityContext,
  ROLES,
  Can
} from 'features/Permission'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import { useRFPDetailsPopupContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsPopupProvider'
import { ROLES_NAMES } from 'constants/common'
import { Avatar } from 'components/Avatar/Avatar'
import { useSelector } from 'react-redux'
import { getUser } from 'redux/store/user/getters'
import { Reassign } from 'features/Reassign/ReassignPopup'
import { RFP_STATUSES } from '../../../constants'
import { StartChat } from 'components/StartChat/StartChat'
import { onStartChat } from 'features/MyCommunity/utils'

type Props = {
  user: TResponsibleUser
}

export const UserListItem: FC<Props> = (props) => {
  const { user } = props

  const currentUser = useSelector(getUser)

  const [isOpenReassign, setIsOpenReassign] = useState(false)

  const ability = useAbility<any>(AbilityContext)
  const rfpDetailsContext = useRFPDetailsContext()
  const { handleGetRfpData } = rfpDetailsContext.actions
  const { deleteResponsibleUserPopup } = useRFPDetailsPopupContext()

  const { data, isVendor, isCommons } = rfpDetailsContext.state
  const { open } = deleteResponsibleUserPopup.actions

  const name = user.first_name + ' ' + user.last_name
  const capitalCharName = `${user.first_name?.charAt(0) || ''}${
    user.last_name?.charAt(0) || ''
  }`.toUpperCase()
  const onDelete = () => {
    if (user) {
      open({ user })
    }
  }

  const rfpSubject = {
    ...data,
    vendor_responsibles: data.vendor_contract_steward
      ? [data.vendor_contract_steward, ...(data.vendor_responsibles || [])]
      : data.vendor_responsibles || []
  }

  const canDeleteResponsibleUser =
    data.status !== RFP_STATUSES.CANCELED &&
    data.status !== RFP_STATUSES.ARCHIVED &&
    ability.can(
      ACTIONS.DELETE,
      subject(SUBJECTS.RESPONSIBLE_USER, rfpSubject)
    ) &&
    (isVendor
      ? user.role === ROLES.VENDOR_ANALYST || user.role === ROLES.VENDOR_LEGAL
      : isCommons
      ? user.role === ROLES.COMMONS_ANALYST || user.role === ROLES.COMMONS_LEGAL
      : user.role === ROLES.ANALYST || user.role === ROLES.LEGAL)

  let canReassignOwner = isVendor
    ? currentUser.role === ROLES.VENDOR_CONTRACT_ADMIN &&
      user.role === ROLES.VENDOR_CONTRACT_STEWARD
    : !!currentUser.commons
    ? currentUser.role === ROLES.COMMONS_VICE_PRESIDENT &&
      (user.role === ROLES.COMMONS_VICE_PRESIDENT ||
        user.role === ROLES.COMMONS_CONTRACT_STEWARD)
    : (currentUser.role === ROLES.VICE_PRESIDENT ||
        currentUser.role === ROLES.COMMUNITY_PRESIDENT) &&
      (user.role === ROLES.COMMUNITY_PRESIDENT ||
        user.role === ROLES.VICE_PRESIDENT ||
        user.role === ROLES.CONTRACT_STEWARD)

  if (
    (canReassignOwner && data.status === RFP_STATUSES.CANCELED) ||
    data.status === RFP_STATUSES.ARCHIVED
  ) {
    canReassignOwner = false
  }

  return (
    <>
      <div className="rfp-details-responsible-user">
        <div className="rfp-details-responsible-user__user">
          <div className="rfp-details-responsible-user__avatar">
            <Avatar
              name={capitalCharName}
              alt="Responsible user image"
              src={user.avatar?.file_preview}
            />
          </div>
          <div className="rfp-details-responsible-user__content">
            <Typography.Body1 weight="semi-bold">{name}</Typography.Body1>
            <Typography.Caption className="rfp-details-responsible-user__role">
              {ROLES_NAMES[user.role]}
            </Typography.Caption>
          </div>
        </div>
        <div className="rfp-details-responsible-user__actions flex">
          {canDeleteResponsibleUser ? (
            <Button.Icon
              type={BUTTON_TYPES.LINK}
              icon={<Delete16 />}
              onClick={onDelete}
              small
              danger
            />
          ) : (
            canReassignOwner && (
              <Button.Icon
                type={BUTTON_TYPES.LINK}
                icon={<ReassignIcon />}
                onClick={() => setIsOpenReassign(true)}
                small
              />
            )
          )}
          <Can
            I={ACTIONS.CREATE}
            a={subject(SUBJECTS.CHAT, {
              ...data,
              ...currentUser
            })}
          >
            <StartChat
              onClick={() => onStartChat(user.uuid)}
              type={BUTTON_TYPES.LINK}
            />
          </Can>
        </div>
      </div>
      {canReassignOwner && (
        <Reassign
          isRFP
          isReassignPopupOpen={isOpenReassign}
          setIsReassignPopupOpen={setIsOpenReassign}
          ownerRole={user.role}
          ownerName={name}
          uuid={data?.uuid}
          update={handleGetRfpData}
        />
      )}
    </>
  )
}
