import Fetcher from '../../../../../../../../services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from '../../../../../../../../constants'
import { DisputeReplyRequest } from './types'

const fetcher = new Fetcher({})

export const sendDisputeReply: DisputeReplyRequest = async (id, data) =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.REBATES_DASHBOARD}${id}/${API_DICTIONARY.INVOICES}${API_DICTIONARY.DISPUTE}${API_DICTIONARY.REPLY}`,
    method: HTTP_METHODS.POST,
    data
  })
