import {
  API_DICTIONARY,
  HTTP_METHODS,
  ResponseWithPagination,
  TOptionsResponse
} from '../../constants'
import Fetcher from '../../services/Fetcher'
import { TAddBusinessDetails } from './BusinessDetails/types'

const fetcher = new Fetcher({})

export const fetchTaxTypeOptions = async () =>
  fetcher.request<unknown, ResponseWithPagination<TOptionsResponse>>({
    url: `${API_DICTIONARY.PAYMENTS}${API_DICTIONARY.ORUM}${API_DICTIONARY.BUSINESS}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.TAX_ID_TYPE}`,
    method: HTTP_METHODS.GET
  })

export const fetchCountriesOptions = async () =>
  fetcher.request<unknown, ResponseWithPagination<TOptionsResponse>>({
    url: `${API_DICTIONARY.PAYMENTS}${API_DICTIONARY.ORUM}${API_DICTIONARY.BUSINESS}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.COUNTRY}`,
    method: HTTP_METHODS.GET
  })

export const fetchStateOptions = async () =>
  fetcher.request<unknown, ResponseWithPagination<TOptionsResponse>>({
    url: `${API_DICTIONARY.PAYMENTS}${API_DICTIONARY.ORUM}${API_DICTIONARY.BUSINESS}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.STATE}`,
    method: HTTP_METHODS.GET
  })

export const fetchAddressTypeOptions = async () =>
  fetcher.request<unknown, ResponseWithPagination<TOptionsResponse>>({
    url: `${API_DICTIONARY.PAYMENTS}${API_DICTIONARY.ORUM}${API_DICTIONARY.BUSINESS}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.ADDRESS_TYPE}`,
    method: HTTP_METHODS.GET
  })
export const fetchAccountTypeOptions = async () =>
  fetcher.request<unknown, ResponseWithPagination<TOptionsResponse>>({
    url: `${API_DICTIONARY.PAYMENTS}${API_DICTIONARY.ORUM}${API_DICTIONARY.BANK_ACCOUNT}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.ACCOUNT_TYPE}`,
    method: HTTP_METHODS.GET
  })

export const addBusinessAccount = (data: Partial<TAddBusinessDetails>) =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.PAYMENTS}${API_DICTIONARY.ORUM}${API_DICTIONARY.BUSINESS}`,
    method: HTTP_METHODS.POST,
    data
  })

export const getBusinessDetailsCommunity = async () =>
  fetcher.request<unknown, TAddBusinessDetails>({
    url: `${API_DICTIONARY.PAYMENTS}${API_DICTIONARY.ORUM}${API_DICTIONARY.BUSINESS}${API_DICTIONARY.COMMUNITY}`,
    method: HTTP_METHODS.GET
  })

export const getBusinessDetailsHealthSystem = async (uuid?: string) =>
  fetcher.request<unknown, TAddBusinessDetails>({
    url: `${API_DICTIONARY.PAYMENTS}${API_DICTIONARY.ORUM}${API_DICTIONARY.BUSINESS}${API_DICTIONARY.HEALTH_SYSTEM}${uuid}/`,
    method: HTTP_METHODS.GET
  })
