import { Tooltip } from '../../../../components/Tooltip/Tooltip'
import {
  currencyFormatter,
  sumPercentage
} from '../../../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { TLinearChart } from '../../../../pages/Compliance/types'
import { LeadByIndicator } from '../../../../components/LeadByIndicator'
import { useMemo } from 'react'
type TProps = {
  item: TLinearChart
}
const ItemLine = ({ item }: TProps) => {
  const {
    compliant,
    non_compliant,
    current_compliance,
    compliance_requirement
  } = item
  const requiredCompliancePercentage = useMemo(() => {
    if (!!compliance_requirement) {
      return parseFloat(compliance_requirement)
    } else return 0
  }, [compliance_requirement])

  const positionComplianceRequired = (requiredCompliancePercentage / 100) * 344

  const compliantPercentage = (+compliant / (+compliant + +non_compliant)) * 100
  const isInCompliant = requiredCompliancePercentage <= compliantPercentage

  return (
    <div className="row space-between full-width align-center gap-24">
      {!!item?.led_by && <LeadByIndicator value={item.led_by} />}
      <div className="table__chart">
        <Tooltip
          className="chart-tooltip-compliance"
          title={
            <div>
              Compliant spend: ${currencyFormatter(compliant || 0)} (
              {sumPercentage(compliant, non_compliant)}%)
              <br />
              Non-compliant spend: ${currencyFormatter(non_compliant || 0)}(
              {sumPercentage(non_compliant, compliant)}%)
              <br />
              {isInCompliant && !!requiredCompliancePercentage && (
                <>
                  Compliance requirement: {requiredCompliancePercentage}
                  % <br />
                </>
              )}
              {!!current_compliance && (
                <>Current compliance: {current_compliance}% </>
              )}
            </div>
          }
          placement="topLeft"
        >
          <div
            className="table__chart__colored"
            style={{
              backgroundColor: '#73A8D3',
              height: '16px',
              width: `${(344 * +compliant) / (+compliant + +non_compliant)}px`
            }}
          >
            {isInCompliant && !!requiredCompliancePercentage && (
              <div
                className="compliance_required_line"
                style={{
                  left: `${positionComplianceRequired}px`
                }}
              />
            )}
          </div>
        </Tooltip>
        <Tooltip
          className="chart-tooltip-compliance"
          title={
            <div>
              Compliant spend: ${currencyFormatter(compliant || 0)}(
              {sumPercentage(compliant, non_compliant)}%) <br />
              Non-compliant spend: ${currencyFormatter(non_compliant || 0)}(
              {sumPercentage(non_compliant, compliant)}%)
              <br />
              {!isInCompliant && !!requiredCompliancePercentage && (
                <>
                  Compliance requirement: {requiredCompliancePercentage}
                  % <br />
                </>
              )}
              {!!current_compliance && (
                <>Current compliance: {current_compliance}% </>
              )}
            </div>
          }
          placement="topLeft"
        >
          <div
            className="table__chart__colored"
            style={{
              backgroundColor: '#F99',
              height: '16px',
              width: `${
                (344 * +non_compliant) / (+compliant + +non_compliant)
              }px`
            }}
          >
            {!isInCompliant && !!requiredCompliancePercentage && (
              <div
                style={{
                  left: `${
                    ((requiredCompliancePercentage - compliantPercentage) /
                      100) *
                    344
                  }px`
                }}
                className="compliance_required_line"
              />
            )}
          </div>
        </Tooltip>
      </div>
    </div>
  )
}

export default ItemLine
