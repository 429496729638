import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, BUTTON_TYPES } from '../../../../../../../../components/Button'
import { Popup } from '../../../../../../../../components/Popup'
import { Typography } from '../../../../../../../../components/Typography'
import { ActionReasonPopup } from '../ActionReasonPopup/ActionReasonPopup'
import { sendDisputeReply } from './api'
import { useFinancialsActionReasonPopup } from '../../../../../../Providers/FinancialsActionReasonPopupProvider'
import { useFinancialsContext } from '../../../../../../Providers/FinancialsProvider'
import { setLoading } from '../../../../../../../../redux/store/common/slice'
import { BTN_TXT } from '../../../../../../../../constants'
import { ReactComponent as Close16 } from 'assets/svg/Clos16.svg'
import { ReactComponent as Check16 } from 'assets/svg/Check16.svg'
import { MODAL_TYPES } from '../ActionReasonPopup/types'
import { FACILITY_STATUSES } from '../types'
import { ActionsProps } from '../types'

export const CommunityActions = ({
  selectedRowKeys,
  setSelectedRowKeys,
  updateData
}: ActionsProps) => {
  const [isAcceptPopupOpen, setIsAcceptPopupOpen] = useState(false)
  const {
    actions: { onOpenPopup }
  } = useFinancialsActionReasonPopup()
  const {
    state: { id }
  } = useFinancialsContext()

  const dispatch = useDispatch()

  const onCloseAcceptDispute = () => {
    setIsAcceptPopupOpen(false)
    setSelectedRowKeys([])
  }

  const onDeclineDispute = async (reason: string) => {
    try {
      await sendDisputeReply(id, {
        facility_approval_reason: reason,
        facility_approval_status: FACILITY_STATUSES.DECLINED,
        document_keys: selectedRowKeys
      })
    } finally {
    }
  }

  const onAcceptDispute = async () => {
    try {
      dispatch(setLoading(true))
      await sendDisputeReply(id, {
        facility_approval_status: FACILITY_STATUSES.ACCEPTED,
        document_keys: selectedRowKeys
      })
      onCloseAcceptDispute()
      updateData()
    } finally {
      dispatch(setLoading(false))
    }
  }

  return (
    <>
      <Button
        icon={<Close16 />}
        onClick={() => onOpenPopup(MODAL_TYPES.DECLINE_DISPUTE)}
        danger
        upperCase
      >
        {BTN_TXT.DECLINE_DISPUTE}
      </Button>
      <Button
        type={BUTTON_TYPES.PRIMARY}
        icon={<Check16 />}
        onClick={() => setIsAcceptPopupOpen(true)}
        upperCase
      >
        {BTN_TXT.ACCEPT_DISPUTE}
      </Button>
      <ActionReasonPopup
        onSubmit={onDeclineDispute}
        setSelectedRowKeys={setSelectedRowKeys}
        updateData={updateData}
      />
      <Popup
        title="Accept dispute?"
        visible={isAcceptPopupOpen}
        onCancel={onCloseAcceptDispute}
        footer={
          <>
            <Button
              type={BUTTON_TYPES.DEFAULT}
              onClick={onCloseAcceptDispute}
              upperCase
            >
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              onClick={onAcceptDispute}
              upperCase
            >
              {BTN_TXT.ACCEPT}
            </Button>
          </>
        }
      >
        <Typography.Body1>Do you want to accept dispute?</Typography.Body1>
      </Popup>
    </>
  )
}
