import { useMemo, useState } from 'react'
import { Form } from 'antd'
import cn from 'classnames'
import { ReactComponent as ChevronDown16 } from 'assets/svg/ChevronDown16.svg'
import { ReactComponent as ChevronUp16 } from 'assets/svg/ChevronUp16.svg'
import './styles.scss'

import { BTN_TXT, LABELS, PLACEHOLDERS } from 'constants/txt'
import { Input } from 'components/Input'
import { Datepicker } from 'components/Datepicker'
import { timelineDateFormat } from 'components/Timelines/constants'
import { IContractDetailsForm } from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsForm/types'
import useContractDetailsForm from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsForm/hooks/useContractDetailsForm'
import { twoDecimalParser } from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import ContractDetailsDocuments from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsDocuments/ContractDetailsDocuments'
import AdditionalInfoPreview from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsAdditionalInfo/AdditionalInfoPreview'
import AdditionalInfoForm from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsAdditionalInfo/AdditionalInfoForm'
import { useContractDetailsContext } from '../../Providers/ContractDetailsContextProvider'
import usePopup from '../../../../hooks/usePopup'
import { Select } from '../../../../components/Select'
import { FloatFooter } from '../../../../components/FloatFooter/FloatFooter'

const ContractDetailsForm = () => {
  const {
    form,
    isCreation,
    isFormRequired,
    hasFormChanges,
    handleFinishForm,
    handleCancelForm,
    handleChangeForm,
    finishDateFieldStatus,
    disabledDateSameOrBeforeStartDate,
    isChanged,
    isActive,
    setIsChanged
  } = useContractDetailsForm()
  const contractDetailsContext = useContractDetailsContext()
  const discardChangesPopup = usePopup()
  const { adminPaymentFrequencyOptions, paymentTermsOptions } =
    contractDetailsContext.state
  const [firstLoad, setFirstLoad] = useState(false)

  const adminFee = Form.useWatch('admin_fee', form)

  const StartDateField = useMemo(
    () => (
      <Datepicker
        propsItem={{
          name: 'start_date',
          label: LABELS.START_DATE,
          rules: [
            {
              required: isFormRequired,
              message: ''
            }
          ]
        }}
        propsDate={{
          format: timelineDateFormat,
          onChange: handleChangeForm
        }}
      />
    ),
    [handleChangeForm, isFormRequired]
  )

  const FinishDateField = useMemo(
    () => (
      <Datepicker
        propsItem={{
          name: 'finish_date',
          label: LABELS.FINISH_DATE,
          rules: [
            {
              required: isFormRequired,
              message: ''
            }
          ]
        }}
        propsDate={{
          format: timelineDateFormat,
          disabledDate: disabledDateSameOrBeforeStartDate,
          status: finishDateFieldStatus,
          onChange: handleChangeForm
        }}
      />
    ),
    [
      disabledDateSameOrBeforeStartDate,
      finishDateFieldStatus,
      handleChangeForm,
      isFormRequired
    ]
  )

  const AdminFeeField = useMemo(
    () => (
      <Input.Number
        className="spinner-input-number"
        propsItem={{
          name: 'admin_fee',
          label: LABELS.ADMIN_FEE,
          rules: [
            {
              required: true,
              message: ''
            }
          ]
        }}
        propsInputNumber={{
          controls: { downIcon: <ChevronDown16 />, upIcon: <ChevronUp16 /> },
          min: '0',
          max: '3',
          parser: twoDecimalParser,
          placeholder: '0',
          onChange: handleChangeForm
        }}
      />
    ),
    [handleChangeForm, isFormRequired]
  )

  const AdminPaymentFrequencyField = useMemo(
    () => (
      <Select
        options={adminPaymentFrequencyOptions}
        propsItem={{
          name: 'admin_payment_frequency',
          label: LABELS.ADMIN_PAYMENT_FREQUENCY,
          rules: [
            {
              required: !!Number(adminFee),
              message: 'Required'
            }
          ]
        }}
        propsSelect={{
          placeholder: PLACEHOLDERS.PLEASE_SELECT,
          onChange: handleChangeForm
        }}
      />
    ),
    [handleChangeForm, adminFee, adminPaymentFrequencyOptions]
  )
  const PaymentTermsField = useMemo(
    () => (
      <Select
        options={paymentTermsOptions}
        propsItem={{
          name: 'payment_term_days',
          label: LABELS.PAYMENT_TERM_DAYS,
          rules: [
            {
              required: !!Number(adminFee),
              message: 'Required'
            }
          ]
        }}
        propsSelect={{
          placeholder: '0',
          onChange: handleChangeForm
        }}
      />
    ),
    [handleChangeForm, adminFee, paymentTermsOptions]
  )

  const submitCancellation = () => {
    handleCancelForm()
    discardChangesPopup.actions.close()
    setIsChanged(false)
  }

  const FormActions = useMemo(
    () => (
      <FloatFooter
        isActive={isActive}
        onCancel={submitCancellation}
        onSave={form.submit}
        isSaveDisabled={!isChanged}
        saveBtnText={isCreation ? BTN_TXT.CREATE : BTN_TXT.SAVE}
      />
    ),
    [submitCancellation, form.submit, isActive, isChanged, isCreation]
  )

  return (
    <>
      <Form<IContractDetailsForm>
        id="contract-details-form"
        form={form}
        onChange={handleChangeForm}
        onFinish={handleFinishForm}
        onFieldsChange={() => {
          if (!firstLoad) setFirstLoad(true)
          else setIsChanged(true)
        }}
        className={cn({ 'contract-details-form--creation': isCreation })}
      >
        <div className="contract-details-form__grid-row">
          <div className="contract-details-form__grid-column">
            <div className="contract-details-form__grid-row two-columns">
              {StartDateField}
              {FinishDateField}
            </div>
            <div className="contract-details-form__grid-row two-columns">
              {AdminFeeField}
              {AdminPaymentFrequencyField}
            </div>
            <div className="admin-fee-field">{PaymentTermsField}</div>
          </div>
          {isCreation ? (
            <AdditionalInfoForm form={form} />
          ) : (
            <AdditionalInfoPreview />
          )}
        </div>
      </Form>
      {isCreation || <ContractDetailsDocuments />}
      {(hasFormChanges || isCreation) && FormActions}
    </>
  )
}

export default ContractDetailsForm
