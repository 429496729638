import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Popup } from '../../../../../components/Popup'
import { Button, BUTTON_TYPES } from '../../../../../components/Button'
import { InvoicesDetails } from './InvoicesDetails/InvoicesDetails'
import { getUser } from '../../../../../redux/store/user/getters'
import { ACTIONS, defineAbility, SUBJECTS } from '../../../Abilities'
import { InvoicesDetailsPopupProps } from './types'

export const InvoicesDetailsPopup = ({
  invoiceData
}: InvoicesDetailsPopupProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const user = useSelector(getUser)
  const ability = defineAbility(user)
  const canSeeDetails = ability.can(ACTIONS.VIEW, SUBJECTS.INVOICE_DETAILS)
  const invoiceBtnText = useMemo(
    () => `Invoices (${invoiceData.invoice_count})`,
    [invoiceData.invoice_count]
  )

  const onCancel = () => {
    setIsOpen(false)
  }

  return (
    <>
      {canSeeDetails ? (
        <Button onClick={() => setIsOpen(true)} type={BUTTON_TYPES.LINK}>
          {invoiceBtnText}
        </Button>
      ) : (
        invoiceBtnText
      )}
      <Popup
        title="See invoice details"
        width={1376}
        visible={isOpen}
        onCancel={onCancel}
        footer={null}
      >
        <InvoicesDetails invoiceData={invoiceData} />
      </Popup>
    </>
  )
}
