import { TAddBusinessDetails } from './BusinessDetails/types'

export const PAYMENT_DETAILS_OPTIONS = [
  { label: 'Business details', value: 'business_details' },
  {
    label: 'Bank accounts',
    value: 'bank_accounts',
    disabled: true
  }
]

export const addBusinessDetailsInitialValues: TAddBusinessDetails = {
  legal_name: '',
  tax_id: '',
  tax_id_type: null,
  incorporation_date: '',
  description: '',
  uuid: '',
  status: '',
  country: null,
  address_type: null,
  state: null,
  city: '',
  zip_code: '',
  address: ''
}
