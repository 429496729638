import { SortOrder } from 'antd/lib/table/interface'
import { TPageInfo, TQueryParams } from '../../../../components/Table/types'
import { AxiosResponse } from 'axios'
import { ResponseWithPagination } from '../../../../constants'
import { FINANCIAL_STATUS } from '../constants'
import { IUser } from '../../../../redux/store/user/types'
import { PaymentStatusParams } from '../types'

export enum DueDateStatuses {
  DUE_SOON = 'due_soon',
  OVERDUE = 'overdue'
}

export type TRebatesTableType = {
  uuid: string
  supplier_name: string
  health_system_name: string
  hospital_name: string
  period_ending: number
  due_date: string
  due_date_status: DueDateStatuses
  invoice_count: number
  total: number
  status: keyof typeof FINANCIAL_STATUS
}

export type TRebatesTableFilters = {
  vendors?: string[]
  health_systems?: string[]
  hospitals?: string[]
  statuses?: string[]
}

export type TRebatesTableFunc = (
  field: keyof TRebatesTableFilters,
  filters: string[]
) => void

export type TRebatesTableColumnsFunc = {
  applyFilters: TRebatesTableFunc
  filters: TRebatesTableFilters
  user: IUser
}

export type TRebatesTableRequestData = {
  sortField?: keyof TRebatesTableType
  sortOrder?: SortOrder
  filters?: TRebatesTableFilters
  page?: TPageInfo
}

export type TRebatesTableParams = TRebatesTableFilters &
  TQueryParams &
  PaymentStatusParams

export type TRebatesTableRequest = (data: {
  params?: TRebatesTableParams
}) => Promise<AxiosResponse<ResponseWithPagination<TRebatesTableType>>>
