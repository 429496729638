import { Form } from 'antd'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Popup } from '../../../../../../../../components/Popup'
import { Input } from '../../../../../../../../components/Input'
import { Banner } from '../../../../../../../../components/Banner/Banner'
import { Typography } from '../../../../../../../../components/Typography'
import { Button, BUTTON_TYPES } from '../../../../../../../../components/Button'
import { useFinancialsContext } from '../../../../../../Providers/FinancialsProvider'
import { useFinancialsActionReasonPopup } from '../../../../../../Providers/FinancialsActionReasonPopupProvider'
import { setLoading } from '../../../../../../../../redux/store/common/slice'
import { DEFAULT_CONFIG, POPUP_CONFIGS } from './constants'
import { BTN_TXT, LABELS } from '../../../../../../../../constants'
import { ActionReasonForm, ActionReasonPopupProps } from './types'

export const ActionReasonPopup = ({
  onSubmit,
  setSelectedRowKeys,
  updateData
}: ActionReasonPopupProps) => {
  const [form] = Form.useForm<ActionReasonForm>()
  const watchReason = Form.useWatch('reason', form)
  const {
    state: { type },
    actions: { onClosePopup }
  } = useFinancialsActionReasonPopup()
  const {
    actions: { updateTableData }
  } = useFinancialsContext()
  const { title, btnText, content } = useMemo(
    () => (type ? POPUP_CONFIGS[type] : DEFAULT_CONFIG),
    [type]
  )
  const dispatch = useDispatch()

  const onClose = () => {
    onClosePopup()
    setSelectedRowKeys([])
    form.resetFields()
  }

  const onFinishForm = async ({ reason }: ActionReasonForm) => {
    try {
      dispatch(setLoading(true))
      await onSubmit(reason)
      onClose()
      updateData()
      updateTableData()
    } finally {
      dispatch(setLoading(false))
    }
  }

  return (
    <Popup
      title={title}
      visible={!!type}
      onCancel={onClose}
      footer={
        <>
          <Button type={BUTTON_TYPES.DEFAULT} onClick={onClose} upperCase>
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            disabled={!watchReason}
            form="action-reason-form"
            htmlType="submit"
            upperCase
          >
            {btnText}
          </Button>
        </>
      }
    >
      <div className="column gap-16">
        <Banner.Warning text="Please note that the reason provided will be applied to all selected invoices." />
        <Typography.Body1>{content}</Typography.Body1>
        <Form<ActionReasonForm>
          id="action-reason-form"
          form={form}
          onFinish={onFinishForm}
        >
          <Input.TextArea
            propsItem={{
              name: 'reason',
              label: LABELS.REASON
            }}
            propsTextArea={{ maxLength: 250, showCount: true }}
          />
        </Form>
      </div>
    </Popup>
  )
}
