import { useCallback } from 'react'

import './styles.scss'

import { AttachmentsList } from 'components/AttachmentsList/AttachmentsList'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import { Typography } from 'components/Typography'
import { BTN_TXT } from 'constants/txt'
import { DOCUMENT_TYPE } from 'components/FileUpload/constants'
import { validateFileTypes } from 'helper/common'
import { FileUpload } from 'components/FileUpload/FileUpload'
import { TDocumentTypeUnion } from 'components/FileUpload/types'
import { getAcceptFileTypesByDocumentType } from 'components/FileUpload/utils'
import ContractVersionHistory from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsDocuments/ContractVersionHistory/ContractVersionHistory'
import { ATTACHMENTS_FILE_TYPES } from 'features/ContractDetails/constants'
import { ReactComponent as Docs16 } from 'assets/svg/Docs16.svg'
import { formatAmendmentDocument } from './utils'

const ContractDetailsDocuments = () => {
  const contractDetailsContext = useContractDetailsContext()

  const {
    details,
    contractDocuments,
    canEditContract,
    canUploadDocuments,
    isOnboarded,
    isAutoGeneratedContract
  } = contractDetailsContext.state
  const {
    handleUploadAttachmentsAsync,
    handleUploadContractDocumentsAsync,
    handleDeleteAttachmentsAsync
  } = contractDetailsContext.actions

  const disableAttachmentListActions = !canEditContract

  const renderUploadFileButton = useCallback(
    (documentType: TDocumentTypeUnion, isContract?: boolean) => {
      const acceptFileTypes = isContract
        ? ATTACHMENTS_FILE_TYPES
        : getAcceptFileTypesByDocumentType(documentType)

      const handleUploadFile = isContract
        ? handleUploadContractDocumentsAsync
        : handleUploadAttachmentsAsync

      const handleDropAcceptedFile = async (file) => {
        await handleUploadFile(file, documentType)
      }

      const validateFile = (file) => validateFileTypes(acceptFileTypes, file)

      return canUploadDocuments ? (
        <FileUpload
          documentType={documentType}
          uploadBtnText={BTN_TXT.UPLOAD}
          handleUploadFile={handleUploadFile}
          uploadFilesProps={{
            multiple: false,
            maxSize: 5242880,
            onDropAccepted: handleDropAcceptedFile,
            accept: acceptFileTypes,
            validator: validateFile
          }}
        />
      ) : null
    },
    [
      canUploadDocuments,
      handleUploadContractDocumentsAsync,
      handleUploadAttachmentsAsync
    ]
  )

  const hasAnyContractDocumentTemplates = Boolean(
    details?.contract_document_templates?.length
  )
  const hasAnyContractDocuments = Boolean(contractDocuments?.length)
  const hasAnyContractAttachments = Boolean(
    details?.contract_attachments?.length
  )
  const amendmentDocument = !!details?.amendment_document
    ? [formatAmendmentDocument(details.amendment_document)]
    : []
  const hasAmendmentDocument = !!amendmentDocument.length && isOnboarded

  const isDocuments =
    canEditContract ||
    hasAnyContractDocumentTemplates ||
    hasAnyContractDocuments ||
    hasAnyContractAttachments ||
    hasAmendmentDocument

  console.log(isAutoGeneratedContract)

  return (
    <>
      {isDocuments && (
        <div className="contact-details-documents">
          <Typography.Headline6 className="contact-details-documents__title">
            Documents
          </Typography.Headline6>
          <div className="contact-details-documents__grid">
            {hasAnyContractDocumentTemplates && !isOnboarded && (
              <div className="contact-details-documents__row">
                <AttachmentsList
                  label="Templates"
                  files={details.contract_document_templates.filter(
                    (d) => d.document_type === 'contract_document'
                  )}
                  disableActions
                />
              </div>
            )}
            <div className="contact-details-documents__row">
              <div>
                {(canEditContract || hasAnyContractDocuments) &&
                  !isOnboarded && (
                    <AttachmentsList.WithHistory
                      label="Contract"
                      files={contractDocuments ?? []}
                      versionHistory={<ContractVersionHistory />}
                    />
                  )}
                {hasAmendmentDocument && (
                  <AttachmentsList
                    label="LOP"
                    files={amendmentDocument}
                    icon={<Docs16 />}
                    disableActions
                  />
                )}
                {renderUploadFileButton(DOCUMENT_TYPE.TEMPLATE, true)}
              </div>
              <div>
                {(canEditContract || hasAnyContractAttachments) && (
                  <AttachmentsList
                    label="Attachments"
                    files={details.contract_attachments}
                    onDelete={handleDeleteAttachmentsAsync}
                    disableActions={disableAttachmentListActions}
                  />
                )}
                {canEditContract &&
                  details.contract_attachments.length < 10 &&
                  renderUploadFileButton(DOCUMENT_TYPE.ATTACHMENTS)}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ContractDetailsDocuments
