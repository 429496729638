import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

import { setLoading } from 'redux/store/common/slice'
import {
  IRFPCCOptions,
  IRFPSurveyCreateRequestPayload,
  IRFPSurveyPayload,
  IRFPVendorCCPayload,
  RFPStakeholderVoteResponseData,
  TRFPSubmitSurveyVotingRequestData
} from 'features/RFP/RFPSurvey/types'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import { useDispatch, useSelector } from 'react-redux'
import {
  createOrUpdateSurveyRequestAsync,
  getRfpVendorCCListRequestAsync,
  getRfpVendorListRequestAsync,
  getSurveyRequestAsync,
  sendSurveyRequestAsync,
  submitSurveyVotingRequestAsync,
  getStakeholderVoteRequestAsync
} from 'features/RFP/RFPSurvey/api'
import { useRFPDetailsPopupContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsPopupProvider'
import { notification } from 'components/Notification'
import { NOTIFICATIONS, VALIDATION_MESSAGES } from 'constants/txt'
import { getUser } from 'redux/store/user/getters'
import { RFP_STATUSES } from 'features/RFP/constants'
import { ROLES } from '../../../Permission'

type ContextProps = {
  state: {
    vendors: IRFPVendorCCPayload[]
    contractCategories: IRFPCCOptions
    selectedVendors: IRFPVendorCCPayload[]
    selectedVendorValues: Partial<IRFPSurveyCreateRequestPayload>[]
    isSurveyCreated: boolean
    isSurveySubmitted: boolean
    createdSurveyData: IRFPSurveyPayload | null
    surveyResponse: RFPStakeholderVoteResponseData | null
  }
  actions: {
    createSurveyAsync: (vendorValues: IRFPSurveyCreateRequestPayload[]) => void
    submitSurveyVotingAsync: (
      submitSurveyVotingData: RFPStakeholderVoteResponseData
    ) => void
    sendSurveyAsync: VoidFunction
    getRfpAcceptedVendorListAsync: () => Promise<void>
    getRfpVendorCCListAsync: (vendorId: string) => Promise<void>
    getStakeholderVoteAsync: () => Promise<void>
  }
}

const RFPSurveyContext = createContext<ContextProps>({
  state: {} as ContextProps['state'],
  actions: {} as ContextProps['actions']
})

const RFPSurveyContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch()

  const rfpDetailsContext = useRFPDetailsContext()
  const { createSurveyPopup, submitSurveyPopup } = useRFPDetailsPopupContext()
  const user = useSelector(getUser)

  const [vendors, _setVendors] = useState<IRFPVendorCCPayload[]>([])
  const [contractCategories, _setContractCategories] = useState<IRFPCCOptions>(
    {}
  )
  const [selectedVendorValues, _setSelectedVendorValues] = useState<
    Partial<IRFPSurveyCreateRequestPayload>[]
  >([])
  const [createdSurveyData, _setCreatedSurveyData] =
    useState<IRFPSurveyPayload | null>(null)
  const [surveyResponse, _setSurveyResponse] =
    useState<RFPStakeholderVoteResponseData | null>(null)

  const selectedVendors = useMemo(
    () =>
      vendors
        ? vendors.filter((vendor) =>
            selectedVendorValues
              .map((record) => record.vendor)
              .includes(vendor.id)
          )
        : [],
    [selectedVendorValues, vendors]
  )

  const isSurveyCreated = useMemo(
    () => selectedVendors.length > 0 || !!surveyResponse?.vote.length,
    [selectedVendors.length, surveyResponse?.vote.length]
  )

  const isSurveySubmitted = useMemo(
    () => selectedVendors.length > 0 && !!surveyResponse?.vote.length,
    [selectedVendors.length, surveyResponse?.vote]
  )

  const handleVendorsChange = useCallback(
    (vendorValues: Partial<IRFPSurveyCreateRequestPayload>[]) => {
      _setSelectedVendorValues(vendorValues)
    },
    []
  )

  const getRfpAcceptedVendorListAsync = useCallback(async () => {
    if (!rfpDetailsContext.state.data.uuid) {
      throw new Error('RFP ID not provided')
    }

    try {
      dispatch(setLoading(true))

      if (
        user?.role !== ROLES.STAKEHOLDER &&
        user?.role !== ROLES.COMMONS_STAKEHOLDER
      ) {
        const rfpVendorListResponse = await getRfpVendorListRequestAsync(
          rfpDetailsContext.state.data.uuid
        )

        if (rfpVendorListResponse.data?.results) {
          _setVendors(rfpVendorListResponse.data?.results)
        }
      }
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [dispatch, rfpDetailsContext.state.data.uuid])

  const getRfpVendorCCListAsync = useCallback(
    async (vendorId: string) => {
      if (!rfpDetailsContext.state.data.uuid) {
        throw new Error('RFP ID not provided')
      }
      if (contractCategories[vendorId]) {
        return
      }

      try {
        dispatch(setLoading(true))

        if (
          user?.role !== ROLES.STAKEHOLDER &&
          user?.role !== ROLES.COMMONS_STAKEHOLDER
        ) {
          const rfpVendorCCListResponse = await getRfpVendorCCListRequestAsync(
            rfpDetailsContext.state.data.uuid,
            vendorId
          )

          if (rfpVendorCCListResponse.data?.results) {
            _setContractCategories((prev) => ({
              ...prev,
              [vendorId]: rfpVendorCCListResponse.data?.results
            }))
          }
        }
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [
      contractCategories,
      dispatch,
      rfpDetailsContext.state.data.uuid,
      user?.role
    ]
  )

  const getRfpSurveyAsync = useCallback(
    async (fillCategories = false) => {
      if (!rfpDetailsContext.state.data.uuid) {
        throw new Error('RFP ID not provided')
      }

      try {
        dispatch(setLoading(true))

        const rfpSurveyResponse = await getSurveyRequestAsync(
          rfpDetailsContext.state.data.uuid
        )

        if (rfpSurveyResponse.data?.survey) {
          _setCreatedSurveyData(rfpSurveyResponse.data)
          _setSelectedVendorValues(
            rfpSurveyResponse.data.survey.map((record) => ({
              vendor: record.vendor.uuid,
              category: record.category.map((cat) => cat.uuid)
            }))
          )
          _setVendors(
            rfpSurveyResponse.data.survey.map((record) => ({
              id: record.vendor.uuid,
              value: record.vendor.name
            }))
          )
          if (fillCategories) {
            _setContractCategories(
              rfpSurveyResponse.data.survey.reduce(
                (contractCategories, record) => {
                  const contractCategoriesList = record.category.map((cat) => ({
                    id: cat.uuid,
                    value: cat.name
                  }))
                  return {
                    ...contractCategories,
                    [record.vendor.uuid]: contractCategoriesList
                  }
                },
                {}
              )
            )
          }
        }
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [dispatch, rfpDetailsContext.state.data.uuid]
  )

  const getStakeholderVoteAsync = useCallback(async () => {
    if (!rfpDetailsContext.state.data.uuid) {
      throw new Error('RFP ID not provided')
    }

    try {
      dispatch(setLoading(true))

      const response = await getStakeholderVoteRequestAsync(
        rfpDetailsContext.state.data.uuid
      )

      if (response?.data?.vote?.length > 0) {
        _setSurveyResponse(response.data)
      } else {
        _setSurveyResponse({ vote: [] })
      }
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [dispatch, rfpDetailsContext.state.data.uuid])

  const createSurveyAsync = useCallback(
    async (vendorCCValues: IRFPSurveyCreateRequestPayload[]) => {
      if (!rfpDetailsContext.state.data.uuid) {
        throw new Error('RFP ID not provided')
      }

      try {
        dispatch(setLoading(true))

        await createOrUpdateSurveyRequestAsync(
          rfpDetailsContext.state.data.uuid,
          vendorCCValues
        )

        createSurveyPopup.actions.close()
        handleVendorsChange(vendorCCValues)
        notification.success({ message: NOTIFICATIONS.SURVEY_CREATED })
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [createSurveyPopup.actions, dispatch, rfpDetailsContext.state.data.uuid]
  )

  const sendSurveyAsync = useCallback(async () => {
    if (!rfpDetailsContext.state.data.uuid) {
      throw new Error('RFP ID not provided')
    }

    try {
      dispatch(setLoading(true))

      await sendSurveyRequestAsync(rfpDetailsContext.state.data.uuid)
      await rfpDetailsContext.actions.handleGetRfpData()
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [dispatch, rfpDetailsContext.actions, rfpDetailsContext.state.data.uuid])

  const submitSurveyVotingAsync = useCallback(
    async (submitSurveyVotingData: RFPStakeholderVoteResponseData) => {
      if (!rfpDetailsContext.state.data.uuid) {
        throw new Error('RFP ID not provided')
      }

      try {
        dispatch(setLoading(true))

        const payloadRequest: TRFPSubmitSurveyVotingRequestData = {
          vote: submitSurveyVotingData.vote.map((data) => ({
            category: data.category.id,
            ranked_vendors: data.ranked_vendors.map(
              (vendor) => vendor.vendor.uuid
            )
          }))
        }

        await submitSurveyVotingRequestAsync(
          rfpDetailsContext.state.data.uuid,
          payloadRequest
        )

        await rfpDetailsContext.actions.handleGetRfpData()

        _setSurveyResponse(submitSurveyVotingData)
        // reset survey data as far as we can now rely on response
        _setCreatedSurveyData(null)
        submitSurveyPopup.actions.close()

        notification.success({ message: VALIDATION_MESSAGES.SM0040 })
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [
      dispatch,
      rfpDetailsContext.actions,
      rfpDetailsContext.state.data.uuid,
      submitSurveyPopup.actions
    ]
  )

  useEffect(() => {
    const status = rfpDetailsContext.state.data.status

    if (
      !user.vendor &&
      (status === RFP_STATUSES.PRICE_FILES_ANALYSIS ||
        status === RFP_STATUSES.VOTING_IN_PROGRESS ||
        status === RFP_STATUSES.VENDORS_SELECTION ||
        status === RFP_STATUSES.ARCHIVED)
    ) {
      getRfpSurveyAsync(
        status === RFP_STATUSES.VENDORS_SELECTION ||
          status === RFP_STATUSES.ARCHIVED
      )
    }
  }, [getRfpSurveyAsync, rfpDetailsContext.state.data.status, user.vendor])

  const state = useMemo(
    () => ({
      vendors,
      contractCategories,
      selectedVendors,
      selectedVendorValues,
      isSurveyCreated,
      isSurveySubmitted,
      createdSurveyData,
      surveyResponse
    }),
    [
      vendors,
      contractCategories,
      selectedVendors,
      selectedVendorValues,
      isSurveyCreated,
      isSurveySubmitted,
      createdSurveyData,
      surveyResponse
    ]
  )

  const actions = useMemo(
    () => ({
      submitSurveyVotingAsync,
      createSurveyAsync,
      sendSurveyAsync,
      getStakeholderVoteAsync,
      getRfpAcceptedVendorListAsync,
      getRfpVendorCCListAsync
    }),
    [
      sendSurveyAsync,
      createSurveyAsync,
      submitSurveyVotingAsync,
      getStakeholderVoteAsync,
      getRfpAcceptedVendorListAsync,
      getRfpVendorCCListAsync
    ]
  )
  return (
    <RFPSurveyContext.Provider value={{ state, actions }}>
      {children}
    </RFPSurveyContext.Provider>
  )
}
export const useRFPSurveyContext = () => useContext(RFPSurveyContext)

export default RFPSurveyContextProvider
