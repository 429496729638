import moment from 'moment'
import { ColumnsType } from 'antd/es/table'
import { SortOrder } from 'antd/es/table/interface'
// import { Dropdown } from '../../../../../../../components/Dropdown'
import { Sorter } from '../../../../../../../components/Sorter/Sorter'
// import { Datepicker } from '../../../../../../../components/Datepicker'
import { Button } from '../../../../../../../components/Button'
import { ReasonCell } from './ReasonCell/ReasonCell'
import { LeadByIndicator } from '../../../../../../../components/LeadByIndicator'
import {
  Status,
  STATUS_TYPES_CLASSNAMES
} from '../../../../../../../components/Status'
// import {
//   getContractCategoryOptions,
//   getDisputeTypeOptions,
//   getFacilityReviewStatusOptions,
//   getLedByOptions
// } from './api'
import { currencyFormatter } from '../../../../../../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { timelineDateFormat } from '../../../../../../../components/Timelines/constants'
// import { ReactComponent as FilterApplied16 } from 'assets/svg/FilterApplied16.svg'
// import { ReactComponent as Filter16 } from 'assets/svg/Filter16.svg'
import { ReactComponent as Information16 } from 'assets/svg/Information.svg'
import {
  DISPUTE_STATUSES,
  FACILITY_STATUSES,
  InvoicesTableColumnsFunc,
  // InvoicesTableFilters,
  // InvoicesTableFunc,
  InvoicesTableRecord
} from './types'

const DISPUTE_STATUS_TO_CLASSNAME = {
  [DISPUTE_STATUSES.DEFERRED]: STATUS_TYPES_CLASSNAMES.LIGHTRED,
  [DISPUTE_STATUSES.EXCLUDED]: STATUS_TYPES_CLASSNAMES.LIGHTBLUE
}

const DISPUTE_STATUS_NAME = {
  [DISPUTE_STATUSES.DEFERRED]: 'Deferred',
  [DISPUTE_STATUSES.EXCLUDED]: 'Excluded'
}

const FACILITY_STATUS_TO_CLASSNAME = {
  [FACILITY_STATUSES.ACCEPTED]: STATUS_TYPES_CLASSNAMES.GREEN,
  [FACILITY_STATUSES.DECLINED]: STATUS_TYPES_CLASSNAMES.LIGHTRED,
  [FACILITY_STATUSES.PENDING]: STATUS_TYPES_CLASSNAMES.YELLOW
}

const FACILITY_STATUS_NAME = {
  [FACILITY_STATUSES.ACCEPTED]: 'Accepted',
  [FACILITY_STATUSES.DECLINED]: 'Declined',
  [FACILITY_STATUSES.PENDING]: 'Pending'
}

export const columns =
  ({}: InvoicesTableColumnsFunc): ColumnsType<InvoicesTableRecord> => [
    {
      dataIndex: 'supplier_invoice_number',
      key: 'supplier_invoice_number',
      width: 174,
      title: 'Supplier Invoice Number',
      sorter: false,
      showSorterTooltip: false
    },
    {
      dataIndex: 'dispute',
      key: 'dispute',
      width: 148,
      // TODO: filters should be implemented from BE in the future
      // filterDropdown: (props) => (
      //   <Dropdown.Filter<InvoicesTableFilters, InvoicesTableFunc, unknown>
      //     applyFilters={applyFilters}
      //     appliedFilters={filters}
      //     searchCallback={() => getDisputeTypeOptions(id)}
      //     valueField="id"
      //     labelField="value"
      //     field="dispute"
      //     asyncSearch
      //     {...props}
      //   />
      // ),
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<InvoicesTableRecord>
          dataIndex="dispute"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Dispute
        </Sorter>
      ),
      render: (_, { dispute }) =>
        dispute?.dispute_type ? (
          <Status.Default
            className={DISPUTE_STATUS_TO_CLASSNAME[dispute.dispute_type]}
          >
            {DISPUTE_STATUS_NAME[dispute.dispute_type]}
          </Status.Default>
        ) : (
          '-'
        ),
      // filterIcon: !!filters?.dispute?.length ? <FilterApplied16 /> : <Filter16 />,
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'dispute',
      key: 'dispute_reason',
      width: 259,
      title: 'Dispute reason',
      sorter: false,
      showSorterTooltip: false,
      render: (_, { dispute }) =>
        dispute?.dispute_reason ? (
          <ReasonCell
            title="Dispute reason"
            trigger="hover"
            reason={dispute.dispute_reason}
            text={dispute.dispute_reason}
          />
        ) : (
          '-'
        )
    },
    {
      dataIndex: 'led_by',
      key: 'led_by',
      width: 104,
      // filterDropdown: (props) => (
      //   <Dropdown.Filter<InvoicesTableFilters, InvoicesTableFunc, unknown>
      //     applyFilters={applyFilters}
      //     appliedFilters={filters}
      //     searchCallback={() => getLedByOptions(id)}
      //     valueField="id"
      //     labelField="value"
      //     field="led_by"
      //     asyncSearch
      //     {...props}
      //   />
      // ),
      title: 'Led by',
      render: (_, { led_by }) => (
        <div className="flex column gap-16">
          {led_by ? <LeadByIndicator value={led_by} /> : '-'}
        </div>
      ),
      // filterIcon: !!filters?.led_by?.length ? <FilterApplied16 /> : <Filter16 />,
      sorter: true,
      showSorterTooltip: false
    },
    {
      // TODO: add link in the future
      dataIndex: 'contract_category_name',
      key: 'contract_category_name',
      width: 259,
      title: 'Contract category',
      // filterDropdown: (props) => (
      //   <Dropdown.Filter<InvoicesTableFilters, InvoicesTableFunc, unknown>
      //     applyFilters={applyFilters}
      //     appliedFilters={filters}
      //     searchCallback={() => getContractCategoryOptions(id)}
      //     valueField="id"
      //     labelField="value"
      //     field="led_by"
      //     asyncSearch
      //     {...props}
      //   />
      // ),
      // filterIcon: !!filters?.invoice_date?.length ? (
      //   <FilterApplied16 />
      // ) : (
      //   <Filter16 />
      // ),
      sorter: false,
      showSorterTooltip: false
    },
    {
      dataIndex: 'invoice_date',
      key: 'invoice_date',
      width: 168,
      // filterDropdown: (props) => (
      //   <div className="filter-dropdown-table-range">
      //     <Datepicker.Filter<InvoicesTableFilters, InvoicesTableFunc, unknown>
      //       applyFilters={applyFilters}
      //       appliedFilters={filters}
      //       field="invoice_date"
      //       {...props}
      //       prefixCls="range-picker-custom ant-picker"
      //       open={props.visible}
      //       rangeClassName="filter-dropdown-table-range-picker"
      //       placement="topLeft"
      //     />
      //   </div>
      // ),
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<InvoicesTableRecord>
          dataIndex="invoice_date"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Invoice Date
        </Sorter>
      ),
      render: (_, { invoice_date }) =>
        invoice_date ? moment(invoice_date).format(timelineDateFormat) : '-',
      // filterIcon: !!filters?.invoice_date?.length ? (
      //   <FilterApplied16 />
      // ) : (
      //   <Filter16 />
      // ),
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'invoice_amount',
      key: 'invoice_amount',
      width: 129,
      title: 'Invoice Amount, $',
      render: (_, { invoice_amount }) => currencyFormatter(invoice_amount),
      sorter: false,
      showSorterTooltip: false
    },
    {
      dataIndex: 'facility_status',
      key: 'facility_status',
      width: 195,
      title: 'Facility review status',
      // filterDropdown: (props) => (
      //   <Dropdown.Filter<InvoicesTableFilters, InvoicesTableFunc, unknown>
      //     applyFilters={applyFilters}
      //     appliedFilters={filters}
      //     searchCallback={getFacilityReviewStatusOptions}
      //     valueField="id"
      //     labelField="value"
      //     field="facility_status"
      //     asyncSearch
      //     {...props}
      //   />
      // ),
      render: (_, { dispute }) =>
        dispute?.facility_approval_status ? (
          <div className="row align-center gap-8">
            <Status.Default
              className={
                FACILITY_STATUS_TO_CLASSNAME[dispute.facility_approval_status]
              }
            >
              {FACILITY_STATUS_NAME[dispute.facility_approval_status]}
            </Status.Default>
            {dispute.facility_approval_status ===
              FACILITY_STATUSES.DECLINED && (
              <ReasonCell
                reason={dispute.facility_approval_reason || ''}
                title="Decline reason"
                triggerElement={<Button.Icon icon={<Information16 />} small />}
              />
            )}
          </div>
        ) : (
          '-'
        ),
      // filterIcon: !!filters?.facility_status?.length ? (
      //   <FilterApplied16 />
      // ) : (
      //   <Filter16 />
      // ),
      sorter: false,
      showSorterTooltip: false
    }
  ]
