import './styles.scss'
import EmptyState from './EmptyState'
import { TAddBusinessDetails } from './types'
import { Forms } from '../../../components/Forms'
import { Form } from 'antd'
import { useEffect } from 'react'
import { convertDetailsToForm } from '../utils'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { Status } from 'components/Status'
import {
  SETTINGS_STATUSES,
  SETTINGS_STATUSES_CLASSNAME,
  SETTINGS_STATUSES_NAME,
  SETTINGS_STATUSES_TOOLTIP
} from '../../../pages/Settings/constants'
import { Tooltip } from 'components/Tooltip/Tooltip'
type TProps = {
  businessDetails: TAddBusinessDetails
}
const BusinessDetails = ({ businessDetails }: TProps) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue(convertDetailsToForm(businessDetails))
  }, [businessDetails])

  return (
    <div className="business-details-container full-width full-height">
      {businessDetails?.uuid ? (
        <>
          <div className="row mb-24 gap-16">
            <Typography.Body1 weight={TYPOGRAPHY_WEIGHT.BOLD}>
              Business details
            </Typography.Body1>
            <Tooltip
              placement="topLeft"
              title={
                SETTINGS_STATUSES_TOOLTIP[
                  businessDetails.status || SETTINGS_STATUSES.CREATED
                ]
              }
            >
              <div>
                <Status.Default
                  className={
                    SETTINGS_STATUSES_CLASSNAME[
                      businessDetails.status || SETTINGS_STATUSES.CREATED
                    ]
                  }
                >
                  {
                    SETTINGS_STATUSES_NAME[
                      businessDetails.status || SETTINGS_STATUSES.CREATED
                    ]
                  }
                </Status.Default>
              </div>
            </Tooltip>
          </div>
          <Forms.AddBusinessDetails
            form={form}
            callback={() => {
              form.resetFields()
            }}
            setIsActive={false}
            setIsChanged={() => console.log(11)}
          />
        </>
      ) : (
        <EmptyState />
      )}
    </div>
  )
}

export default BusinessDetails
