import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Typography } from '../../../../../../components/Typography'
import { FieldWithLabel } from '../../../../../../components/FieldWithLabel/FieldWithLabel'
import { InvoicesTable } from './InvoicesTable/InvoicesTable'
import {
  FINANCIAL_STATUS_TYPES_TO_CLASSNAME,
  Status
} from '../../../../../../components/Status'
import { getFinancialStatus } from '../../../constants'
import { getUser } from '../../../../../../redux/store/user/getters'
import { useFinancialsContext } from '../../../../Providers/FinancialsProvider'
import { currencyFormatter } from '../../../../../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { timelineDateFormat } from '../../../../../../components/Timelines/constants'
import { InvoicesDetailsProps } from './types'
import './styles.scss'

export const InvoicesDetails = ({ invoiceData }: InvoicesDetailsProps) => {
  const user = useSelector(getUser)
  const isVendor = !!user.vendor
  const {
    actions: { setId }
  } = useFinancialsContext()

  useEffect(() => {
    setId(invoiceData.uuid || '')
  }, [invoiceData.uuid])

  return (
    <div className="invoices-details column gap-32">
      <div className="invoices-details__general column gap-16">
        <div className="row gap-12">
          <Typography.Headline6>General</Typography.Headline6>
          <Status.Default
            className={FINANCIAL_STATUS_TYPES_TO_CLASSNAME[invoiceData.status]}
          >
            {getFinancialStatus(invoiceData.status, isVendor)}
          </Status.Default>
        </div>
        <div className="row gap-24">
          <FieldWithLabel title="Vendor">
            {invoiceData.supplier_name}
          </FieldWithLabel>
          <FieldWithLabel title="Total">
            ${currencyFormatter(invoiceData.total)}
          </FieldWithLabel>
          <FieldWithLabel title="Period ending">
            {invoiceData.period_ending
              ? moment(invoiceData.period_ending).format(timelineDateFormat)
              : '-'}
          </FieldWithLabel>
          <FieldWithLabel title="Due date">
            {invoiceData.due_date
              ? moment(invoiceData.due_date).format(timelineDateFormat)
              : '-'}
          </FieldWithLabel>
        </div>
      </div>
      <InvoicesTable />
    </div>
  )
}
