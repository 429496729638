import FinancialsProvider from './Providers/FinancialsProvider'
import FinancialsAbilityProvider from './Providers/FinancialsAbilityProvider'
import FinancialsActionReasonPopupProvider from './Providers/FinancialsActionReasonPopupProvider'
import { FinancialsRouter } from './Router/Router'
import { FinancialsContainer } from './Container/Container'

export const Financials = () => (
  <FinancialsAbilityProvider>
    <FinancialsProvider>
      <FinancialsActionReasonPopupProvider>
        <FinancialsContainer>
          <FinancialsRouter />
        </FinancialsContainer>
      </FinancialsActionReasonPopupProvider>
    </FinancialsProvider>
  </FinancialsAbilityProvider>
)
