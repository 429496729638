export const ACTIONS = {
  VIEW: 'VIEW',
  DISPUTE: 'DISPUTE',
  SELECT: 'SELECT'
}

export const SUBJECTS = {
  REBATES_DASHBOARD: 'REBATES_DASHBOARD',
  REBATE: 'REBATE',
  INVOICE_DETAILS: 'INVOICE_DETAILS',
  INVOICE: 'INVOICE'
}
