import { FC, useCallback, useEffect, useState } from 'react'
import './styles.scss'
import { Select } from 'components/Select'
import { LABELS, PLACEHOLDERS } from 'constants/txt'
import useAdditionalInfo from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsAdditionalInfo/hooks/useAdditionalInfo'
import { Props } from './types'
import { FieldWithLabel } from '../../../../components/FieldWithLabel/FieldWithLabel'
import useRouter from '../../../../hooks/useRouter'
import { fetchHealthSystem } from '../../../HealthSystems/api'
import { useSelector } from 'react-redux'
import { getUser } from '../../../../redux/store/user/getters'
import { LeadByIndicator } from '../../../../components/LeadByIndicator'

const AdditionalInfoForm: FC<Props> = (props) => {
  const {
    vendorOptions,
    categoryOptions,
    contractStewardOptions,
    vendorContractStewardDisabled
  } = useAdditionalInfo(props)

  const { query } = useRouter()
  const [healthSystem, setHealthSystem] = useState('')
  const user = useSelector(getUser)
  const getHealthSystemDetails = useCallback(async () => {
    await fetchHealthSystem(
      user.community as string,
      user.health_system as string
    ).then((resp) => {
      setHealthSystem(resp.data?.name)
    })
  }, [user, query])

  useEffect(() => {
    if (user.community && user.health_system) {
      getHealthSystemDetails()
    }
  }, [query, user])

  return (
    <div className="contract-details__grid-creation">
      {query?.typeCreation === 'health_system' && (
        <FieldWithLabel
          className="contract-details__grid-creation__hs"
          title="Health System"
        >
          {healthSystem}
        </FieldWithLabel>
      )}
      {query?.typeCreation === 'national_gpo' && (
        <FieldWithLabel
          className="contract-details__grid-creation__hs"
          title="Led by"
        >
          <LeadByIndicator value={'national_gpo'} />
        </FieldWithLabel>
      )}
      <Select
        options={vendorOptions}
        propsItem={{
          name: 'vendor',
          label: LABELS.VENDOR,
          rules: [
            {
              required: true,
              message: 'Required'
            }
          ]
        }}
        propsSelect={{
          placeholder: PLACEHOLDERS.PLEASE_SELECT
        }}
      />
      <Select
        options={categoryOptions}
        propsItem={{
          name: 'contract_category',
          label: LABELS.CONTRACT_CATEGORY,
          rules: [
            {
              required: true,
              message: 'Required'
            }
          ]
        }}
        propsSelect={{
          placeholder: PLACEHOLDERS.PLEASE_SELECT
        }}
      />
      <Select
        options={contractStewardOptions}
        propsItem={{
          name: 'vendor_contract_steward',
          label: LABELS.VENDOR_CONTRACT_STEWARD
        }}
        propsSelect={{
          placeholder: PLACEHOLDERS.PLEASE_SELECT,
          disabled: vendorContractStewardDisabled
        }}
      />
    </div>
  )
}

export default AdditionalInfoForm
