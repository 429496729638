import { STATUS_TYPES_CLASSNAMES } from '../../components/Status'

export const SETTINGS_TABS = [
  {
    key: '0',
    label: 'Profile'
  },
  {
    key: '1',
    label: 'Community'
  },
  {
    key: '2',
    label: 'Community users'
  },
  {
    key: '3',
    label: 'Health Systems'
  },
  {
    key: '4',
    label: 'Vendor'
  },
  {
    key: '5',
    label: 'Health System'
  },
  {
    key: '6',
    label: 'Payment details'
  }
]
export const SETTINGS_STATUSES = {
  CREATED: 'created',
  VERIFIED: 'verified',
  REJECTED: 'rejected',
  RESTRICTED: 'restricted',
  CLOSED: 'closed'
} as const

export const SETTINGS_STATUSES_CLASSNAME = {
  [SETTINGS_STATUSES.CREATED]: STATUS_TYPES_CLASSNAMES.LIGHTBLUE,
  [SETTINGS_STATUSES.VERIFIED]: STATUS_TYPES_CLASSNAMES.GREEN,
  [SETTINGS_STATUSES.RESTRICTED]: STATUS_TYPES_CLASSNAMES.RED,
  [SETTINGS_STATUSES.REJECTED]: STATUS_TYPES_CLASSNAMES.RED,
  [SETTINGS_STATUSES.CLOSED]: STATUS_TYPES_CLASSNAMES.RED
}
export const SETTINGS_STATUSES_TOOLTIP = {
  [SETTINGS_STATUSES.CREATED]:
    'Business was created or updated. Verification pending.',
  [SETTINGS_STATUSES.VERIFIED]: 'Business passed KYB verification',
  [SETTINGS_STATUSES.RESTRICTED]:
    'Business did not pass verification. Sending and receiving funds is not available.',
  [SETTINGS_STATUSES.REJECTED]:
    'Business matched a sanctions list during watchlist screening. Sending and receiving funds is not available.',
  [SETTINGS_STATUSES.CLOSED]:
    'Business was soft deleted in payment provider. Sending and receiving funds is not available. '
}

export const SETTINGS_STATUSES_NAME = {
  [SETTINGS_STATUSES.CREATED]: 'Created',
  [SETTINGS_STATUSES.VERIFIED]: 'Verified',
  [SETTINGS_STATUSES.RESTRICTED]: 'Rejected',
  [SETTINGS_STATUSES.REJECTED]: 'Restricted',
  [SETTINGS_STATUSES.CLOSED]: 'Closed'
} as const
