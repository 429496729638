import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
  useState
} from 'react'
import { MODAL_TYPES } from '../Rebates/RebatesTable/InvoicesDetailsPopup/InvoicesDetails/InvoicesTable/ActionReasonPopup/types'

type ContextProps = {
  state: {
    type: MODAL_TYPES | null
  }
  actions: {
    onOpenPopup: (type: MODAL_TYPES) => void
    onClosePopup: () => void
  }
}

const FinancialsActionReasonPopupContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const FinancialsActionReasonPopupProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const [type, setType] = useState<MODAL_TYPES | null>(null)

  const onOpenPopup = (type: MODAL_TYPES) => setType(type)

  const onClosePopup = () => setType(null)

  const context = useMemo(
    () => ({
      state: {
        type
      },
      actions: {
        onOpenPopup,
        onClosePopup
      }
    }),
    [type]
  )

  return (
    <FinancialsActionReasonPopupContext.Provider value={context}>
      {children}
    </FinancialsActionReasonPopupContext.Provider>
  )
}

export const useFinancialsActionReasonPopup = () =>
  useContext(FinancialsActionReasonPopupContext)

export default FinancialsActionReasonPopupProvider
