import {
  createContext,
  FC,
  PropsWithChildren,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Moment } from 'moment'
import { setLoading } from '../../../redux/store/common/slice'
import { getCompletedQuarter, getQuarterAndYear } from '../../../utils/moment'
import { getRebatesTableData } from '../Rebates/RebatesTable/api'
import useTabs, {
  TabsReturnActions,
  TabsReturnState
} from '../../../hooks/useTabs'
import { DEFAULT_PAGE } from '../../../components/Table/constants'
import { FINANCIAL_TABS } from '../constants'
import {
  TRebatesTableParams,
  TRebatesTableRequestData,
  TRebatesTableType
} from '../Rebates/RebatesTable/types'

type ContextProps = {
  state: TabsReturnState & {
    id: string
    totalNumber: number
    firstLoad: boolean
    period: Moment
    sortParams?: TRebatesTableRequestData
    tableData: TRebatesTableType[]
  }
  actions: TabsReturnActions & {
    setId: Dispatch<SetStateAction<string>>
    setPeriod: Dispatch<SetStateAction<Moment>>
    getTableData: (args: TRebatesTableRequestData) => void
    updateTableData: () => void
  }
}
const FinancialsContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const FinancialsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [id, setId] = useState('')
  const [totalNumber, setTotalNumber] = useState(0)
  const [firstLoad, setFirstLoad] = useState(true)
  const [period, setPeriod] = useState<Moment>(getCompletedQuarter())
  const [sortParams, setSortParams] = useState<TRebatesTableRequestData>()
  const [tableData, setTableData] = useState<TRebatesTableType[]>([])
  const dispatch = useDispatch()
  const { tab } = useParams()

  const { state: tabsState, actions: tabsActions } = useTabs({
    tabs: FINANCIAL_TABS,
    activeTab: tab || FINANCIAL_TABS[0].key
  })

  const getTableData = ({
    sortField,
    sortOrder,
    filters,
    page
  }: TRebatesTableRequestData) => {
    dispatch(setLoading(true))
    const params: TRebatesTableParams = {
      ...filters,
      ...getQuarterAndYear(period)
    }
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    const dataPage = page ? page : DEFAULT_PAGE
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    getRebatesTableData({ params })
      .then((resp) => {
        if (!resp.data?.results) {
          return
        }
        setTableData(
          resp.data.results.map((i) => ({
            ...i,
            key: i.uuid
          }))
        )
        setTotalNumber(resp.data.count)
        if (firstLoad) {
          setFirstLoad(false)
        }
      })
      .finally(() => dispatch(setLoading(false)))
  }

  const updateTableData = () => getTableData({ ...sortParams })

  const context = useMemo(
    () => ({
      state: {
        ...tabsState,
        totalNumber,
        sortParams,
        tableData,
        firstLoad,
        period,
        id
      },
      actions: {
        ...tabsActions,
        setId,
        setPeriod,
        getTableData,
        updateTableData
      }
    }),
    [
      tabsState,
      totalNumber,
      sortParams,
      tableData,
      firstLoad,
      period,
      id,
      tabsActions
    ]
  )

  useEffect(() => {
    if (!!tab) tabsActions.setActiveTab(tab)
  }, [tab])

  return (
    <FinancialsContext.Provider value={context}>
      {children}
    </FinancialsContext.Provider>
  )
}

export const useFinancialsContext = () => useContext(FinancialsContext)

export default FinancialsProvider
