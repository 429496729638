import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { getUser } from 'redux/store/user/getters'

import useTabs, { TabsReturnActions, TabsReturnState } from 'hooks/useTabs'
import useRouter from '../../../hooks/useRouter'
import { contractPageRoute } from '../constants'
import { TEvaluateModalData } from '../../../features/CognusRepository/AvailableForEvaluation/types'
import { setOpportunityAnalysisList } from '../../../features/Contracts/api'
import { setLoading } from '../../../redux/store/common/slice'
import {
  CONTRACT_PAGE_TABS_COMMONS,
  CONTRACT_PAGE_TABS_COMMUNITY
} from '../constants'
import { canSeeMyContracts } from 'helper/common'
import { ability, ACTIONS, ROLES } from '../../../features/Permission'
import { getHealthSystemDetails } from '../../../features/MyCommunity/HealthSystems/HealthSystemDetails/api'

type ContextProps = {
  state: {
    isVendor: boolean
    isCommunity?: boolean
    isCanSeeMyContracts: boolean
    isCanSeeCategories: boolean
    isCanSeeShortCategoriesOnly: boolean
    isCanSetUpEditCategory: boolean
    isCanSeeCategoryDetails: boolean
    isCommons?: boolean
    evaluateData: TEvaluateModalData
    isGPOWasSetup?: boolean
    isUserCanSeeGPOOption?: boolean
  } & TabsReturnState
  actions: TabsReturnActions & {
    setEvaluateData: (data: TEvaluateModalData) => void
    handleEvaluation: (data: string[], callback: () => void) => void
  }
}

const ContractPageContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const createRoute = 'create'

const prepareRouteId = (routeId?: string) =>
  routeId ? (routeId === createRoute ? null : routeId) : null

const ContractPageContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { id: routeId, tab } = useParams()
  const user = useSelector(getUser)
  const [evaluateData, setEvaluateData] = useState<TEvaluateModalData>([])
  const { query } = useRouter()
  const dispatch = useDispatch()

  const [_id, setId] = useState<string | null>(prepareRouteId(routeId))
  const [isGPOWasSetup, setIsGPOWasSetup] = useState<boolean>(false)

  const availableTabs = useMemo(() => {
    return user?.commons
      ? CONTRACT_PAGE_TABS_COMMONS
      : CONTRACT_PAGE_TABS_COMMUNITY
  }, [user])

  const isUserCanSeeGPOOption = useMemo(() => {
    return (
      isGPOWasSetup &&
      (user.role === ROLES.CONTRACT_STEWARD ||
        user.role === ROLES.VICE_PRESIDENT ||
        user.role === ROLES.COMMUNITY_PRESIDENT)
    )
  }, [user, isGPOWasSetup])

  const { state: tabsState, actions: tabsActions } = useTabs({
    tabs: availableTabs,
    activeTab: tab || availableTabs[0].key
  })

  const isVendor = useMemo(() => Boolean(user.vendor), [user.vendor])

  const isCommunity = useMemo(() => Boolean(user.community), [user.community])
  const isCommons = useMemo(() => Boolean(user.commons), [user.commons])

  const isCanSeeMyContracts = useMemo(
    () => canSeeMyContracts(user.role),
    [user.role]
  )
  const isCanSeeCategories = useMemo(
    () =>
      isCanSeeMyContracts && user.role !== ROLES.COMMONS_CLINICAL_COORDINATOR,
    [user.role, isCanSeeMyContracts]
  )

  const isCanSeeShortCategoriesOnly = useMemo(
    () => isCanSeeMyContracts && user.role === ROLES.COMMUNITY_PRESIDENT,
    [user.role, isCanSeeMyContracts]
  )

  const isCanSetUpEditCategory = useMemo(() => {
    return (
      user.role === ROLES.COMMONS_CONTRACT_STEWARD ||
      user.role === ROLES.COMMONS_VICE_PRESIDENT ||
      user.role === ROLES.VICE_PRESIDENT ||
      user.role === ROLES.CONTRACT_STEWARD
    )
  }, [user.role])

  const isCanSeeCategoryDetails = useMemo(() => {
    return (
      user.role === ROLES.LEGAL ||
      user.role === ROLES.ANALYST ||
      user.role === ROLES.HOSPITAL_ADMIN ||
      user.role === ROLES.INFLUENCER ||
      user.role === ROLES.STAKEHOLDER ||
      user.role === ROLES.VENDOR_LEGAL ||
      user.role === ROLES.VENDOR_ANALYST ||
      user.role === ROLES.VENDOR_CONTRACT_STEWARD ||
      user.role === ROLES.VENDOR_FINANCE_OFFICER ||
      user.role === ROLES.VENDOR_CONTRACT_ADMIN ||
      user.role === ROLES.COMMONS_LEGAL ||
      user.role === ROLES.COMMONS_ANALYST ||
      user.role === ROLES.COMMONS_CLINICAL_COORDINATOR ||
      user.role === ROLES.COMMONS_STAKEHOLDER ||
      user.role === ROLES.VICE_PRESIDENT ||
      user.role === ROLES.COMMONS_VICE_PRESIDENT ||
      user.role === ROLES.COMMUNITY_PRESIDENT ||
      user.role === ROLES.CONTRACT_STEWARD ||
      user.role === ROLES.COMMONS_CONTRACT_STEWARD
    )
  }, [user.role])

  const _adjustTabsAccessibility = useCallback(() => {
    tabsActions.setTabs(
      availableTabs.map((i) => {
        const disabled =
          (i.key === contractPageRoute.contractPipeline &&
            ability.cannot(ACTIONS.VIEW, i.label)) ||
          (user.role === ROLES.COMMUNITY_PRESIDENT &&
            i.key !== contractPageRoute.contracts)
        return {
          ...i,
          danger: disabled
        }
      })
    )
  }, [tabsActions, query])

  const handleEvaluation = (data: string[], callback: () => void) => {
    dispatch(setLoading(true))
    setOpportunityAnalysisList({ contract_groups: data })
      .then(() => {
        callback()
      })
      .finally(() => dispatch(setLoading(false)))
  }

  useLayoutEffect(() => {
    _adjustTabsAccessibility()
  }, [_adjustTabsAccessibility])

  useEffect(() => {
    setId(prepareRouteId(routeId))
  }, [routeId])

  useEffect(() => {
    if (user.health_system) {
      getHealthSystemDetails(user.health_system).then((resp) => {
        setIsGPOWasSetup(!!resp.data.national_gpo)
      })
    }
  }, [])

  const context = useMemo(
    () => ({
      state: {
        isVendor,
        ...tabsState,
        isCommunity,
        isCommons,
        isCanSeeMyContracts,
        isCanSeeCategories,
        isCanSeeShortCategoriesOnly,
        isCanSeeCategoryDetails,
        isCanSetUpEditCategory,
        evaluateData,
        isGPOWasSetup,
        isUserCanSeeGPOOption
      },
      actions: {
        ...tabsActions,
        setEvaluateData,
        handleEvaluation
      }
    }),
    [
      isCommunity,
      isVendor,
      tabsState,
      tabsActions,
      isCommons,
      isCanSeeMyContracts,
      isCanSeeCategories,
      isCanSeeShortCategoriesOnly,
      isCanSetUpEditCategory,
      isCanSeeCategoryDetails,
      evaluateData,
      setEvaluateData,
      handleEvaluation,
      isGPOWasSetup,
      isUserCanSeeGPOOption
    ]
  )

  return (
    <ContractPageContext.Provider value={context}>
      {children}
    </ContractPageContext.Provider>
  )
}

export const useContractPageContext = () => useContext(ContractPageContext)

export default ContractPageContextProvider
