import { ColumnsType } from 'antd/es/table'
import { CONTARCT_STATUS_TYPES_TO_CLASSNAME, Status } from 'components/Status'
import { Link } from 'components/Link'
import { Dropdown } from 'components/Dropdown'
import { CellWithSubtitle } from 'components/CellWithSubtitle/CellWithSubtitle'
import { CONTRACT_STATUSES_OPTIONS } from 'constants/common'
import { ReactComponent as Filter16 } from 'assets/svg/Filter16.svg'
import { ReactComponent as FilterApplied16 } from 'assets/svg/FilterApplied16.svg'
import { fetchContractCategories, fetchLedBy } from 'pages/CRUDUser/api'
import { Datepicker } from 'components/Datepicker'
import {
  TContractsList,
  TContractsListColumnsFunc,
  TContractsListFilters,
  TContractsListFiltersFunc
} from './types'
import { CONTRACT_STATUS_NAME } from 'features/ContractDetails/constants'
import moment from 'moment'
import { timelineDateFormat } from 'components/Timelines/constants'
import { REBATE_CONDITIONS_TITLE } from '../ContractDetails/RebateConditions/RebateConditionsForm/constants'
import {
  getRebateTypesRequestAsync,
  getVendorsRequestAsync
} from 'features/ContractDetails/api'
import { LeadByIndicator } from '../../components/LeadByIndicator'
import { optionFormatter } from './helpers'
import { routes } from '../../router'
import { Sorter } from '../../components/Sorter/Sorter'
import { SortOrder } from 'antd/es/table/interface'
import CategoriesPopover from '../Categories/CategoriesPopover'
import { TLedByCC } from '../Categories/types'

export const CONTRACT_TYPE_OPTIONS = [
  {
    label: 'Community Contract',
    value: 'community'
  },
  {
    label: 'Health System Contract',
    value: 'health_system'
  },
  {
    label: 'GPO Contract',
    value: 'national_gpo'
  }
]

export const columns = ({
  applyFilters,
  filters,
  isAllColumnsVisible,
  canSeeMyContracts,
  isVendor
}: TContractsListColumnsFunc): ColumnsType<TContractsList> => {
  const _columns: ColumnsType<TContractsList> = [
    {
      dataIndex: 'contract_category_name',
      key: 'contract_category_name',
      width: 284,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TContractsList>
          dataIndex={'contract_category_name'}
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Contract category
        </Sorter>
      ),
      className: 'vertical-align-middle',
      filterDropdown: (props) => (
        <Dropdown.Filter<
          TContractsListFilters,
          TContractsListFiltersFunc,
          unknown
        >
          applyFilters={applyFilters}
          appliedFilters={filters}
          searchCallback={fetchContractCategories}
          field="contract_categories"
          asyncSearch
          {...props}
        />
      ),
      render: (_, { contract_category_name, uuid, led_by }) => (
        <CategoriesPopover
          name={contract_category_name}
          uuid={uuid}
          led_by={(led_by ?? []) as TLedByCC[]}
        />
      ),
      filterIcon: !!filters?.contract_categories?.length ? (
        <FilterApplied16 />
      ) : (
        <Filter16 />
      ),
      filterSearch: true,
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'led_by',
      key: 'led_by',
      width: 160,
      title: 'Led by',
      className: 'vertical-align-middle',
      render: (_, { led_by }) => (
        <div className="flex column gap-16">
          {led_by?.map(({ contracts, led_by }) =>
            contracts?.map((_, idx) => (
              <div className="separate-item">
                {idx === 0 && led_by && <LeadByIndicator value={led_by} />}
              </div>
            ))
          )}
        </div>
      ),
      sorter: true,
      showSorterTooltip: false,
      filterDropdown: (props) => (
        <Dropdown.Filter<
          TContractsListFilters,
          TContractsListFiltersFunc,
          unknown
        >
          applyFilters={applyFilters}
          appliedFilters={filters}
          searchCallback={fetchLedBy}
          field="led_by"
          asyncSearch
          optionFormatter={optionFormatter}
          {...props}
        />
      ),
      filterIcon: !!filters?.led_by?.length ? (
        <FilterApplied16 />
      ) : (
        <Filter16 />
      ),
      filterSearch: true
    },
    {
      dataIndex: 'vendor_name',
      key: 'vendor_name',
      width: 235,
      title: isVendor ? 'Contract' : 'Vendor contract',
      filterDropdown: (props) => (
        <Dropdown.Filter<
          TContractsListFilters,
          TContractsListFiltersFunc,
          unknown
        >
          applyFilters={applyFilters}
          appliedFilters={filters}
          searchCallback={getVendorsRequestAsync}
          field={'vendors'}
          asyncSearch
          {...props}
        />
      ),
      filterIcon:
        !!filters?.communities?.length || !!filters?.vendors?.length ? (
          <FilterApplied16 />
        ) : (
          <Filter16 />
        ),
      render: (_, { led_by }) => (
        <div className="flex column gap-16">
          {led_by?.map(({ contracts }) =>
            contracts?.map(({ vendor_name, led_by_name, uuid }) => (
              <div className="separate-item row align-center" key={uuid}>
                <CellWithSubtitle
                  className="contract-link"
                  title={
                    <Link
                      to={`${routes.contracts}${
                        canSeeMyContracts ? routes.myContracts : ''
                      }/${uuid}`}
                    >
                      {(isVendor ? led_by_name : vendor_name) ||
                        'Cognus Commons'}
                    </Link>
                  }
                />
              </div>
            ))
          )}
        </div>
      )
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: 'Status',
      width: 190,
      filterDropdown: (props) => (
        <Dropdown.Filter<
          TContractsListFilters,
          TContractsListFiltersFunc,
          unknown
        >
          items={CONTRACT_STATUSES_OPTIONS}
          applyFilters={applyFilters}
          appliedFilters={filters}
          field="statuses"
          {...props}
        />
      ),
      render: (_, { led_by }) => (
        <div className="flex column gap-16">
          {led_by?.map(({ contracts }) =>
            contracts?.map(({ status }) => (
              <div className="separate-item row align-center">
                <Status.Default
                  className={CONTARCT_STATUS_TYPES_TO_CLASSNAME[status]}
                >
                  {CONTRACT_STATUS_NAME[status]}
                </Status.Default>
              </div>
            ))
          )}
        </div>
      ),
      filterIcon: !!filters?.statuses?.length ? (
        <FilterApplied16 />
      ) : (
        <Filter16 />
      ),
      filterSearch: true
    },
    {
      dataIndex: 'start_date',
      key: 'start_date',
      width: 160,
      title: 'Start date',
      render: (_, { led_by }) => (
        <div className="flex column gap-16">
          {led_by?.map(({ contracts }) =>
            contracts?.map(({ start_date }) => (
              <div className="separate-item row align-center">
                {start_date
                  ? moment(start_date).format(timelineDateFormat)
                  : '-'}
              </div>
            ))
          )}
        </div>
      ),

      filterDropdown: (props) => {
        return (
          <div className="filter-dropdown-table-range">
            <Datepicker.Filter<
              TContractsListFilters,
              TContractsListFiltersFunc,
              unknown
            >
              applyFilters={applyFilters}
              appliedFilters={filters}
              field="start_date_range"
              {...props}
              prefixCls="range-picker-custom ant-picker"
              open={props.visible}
              rangeClassName="filter-dropdown-table-range-picker"
              placement="topLeft"
            />
          </div>
        )
      },
      filterIcon: !!filters?.start_date_range?.length ? (
        <FilterApplied16 />
      ) : (
        <Filter16 />
      ),
      filterSearch: true
    },
    {
      dataIndex: 'finish_date',
      key: 'finish_date',
      width: 160,
      title: 'Finish date',
      render: (_, { led_by }) => (
        <div className="flex column gap-16">
          {led_by?.map(({ contracts }) =>
            contracts?.map(({ finish_date }) => (
              <div className="separate-item row align-center">
                {finish_date
                  ? moment(finish_date).format(timelineDateFormat)
                  : '-'}
              </div>
            ))
          )}
        </div>
      )
    },
    {
      dataIndex: 'rebate_type',
      key: 'rebate_type',
      width: 168,
      title: 'Rebate type',
      render: (_, { led_by }) => (
        <div className="flex column gap-16">
          {led_by?.map(({ contracts }) =>
            contracts?.map(({ rebate_type }) => (
              <div className="separate-item row align-center">
                <CellWithSubtitle
                  title={
                    rebate_type ? REBATE_CONDITIONS_TITLE[rebate_type] : '-'
                  }
                />
              </div>
            ))
          )}
        </div>
      ),
      filterDropdown: (props) => (
        <Dropdown.Filter<
          TContractsListFilters,
          TContractsListFiltersFunc,
          unknown
        >
          applyFilters={applyFilters}
          appliedFilters={filters}
          searchCallback={getRebateTypesRequestAsync}
          field="rebate_types"
          asyncSearch
          optionFormatter={optionFormatter}
          {...props}
        />
      ),
      filterIcon: !!filters?.rebate_types?.length ? (
        <FilterApplied16 />
      ) : (
        <Filter16 />
      )
    }
  ]

  if (isAllColumnsVisible) {
    _columns.push({
      dataIndex: 'admin_fee',
      key: 'admin_fee',
      width: 136,
      title: 'Admin fee, %',
      render: (_, { led_by }) => (
        <div className="flex column gap-16">
          {led_by?.map(({ contracts }) =>
            contracts?.map(({ admin_fee }) => (
              <div className="separate-item row align-center">{admin_fee}</div>
            ))
          )}
        </div>
      )
    })
  }

  return _columns
}
