import {
  Typography,
  TYPOGRAPHY_WEIGHT
} from '../../../../components/Typography'
import Chart from './Chart'
import { useComplianceContext } from '../../../../pages/Compliance/Providers/ComplianceContextProvider'
import InfiniteList from '../../../../components/InfiniteList/InfiniteList'
import { useMemo } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { TLinearChart } from '../../../../pages/Compliance/types'

const SpendByFacility = () => {
  const { state } = useComplianceContext()
  const { spendByFacility, loadMoreSpendByFacility } = state
  const Loader = useMemo(
    () => <LoadingOutlined className="chat-list-feature-list__spinner" spin />,
    []
  )

  return (
    <div
      className="compliance-container__chart-wrapper  spend-by-category part charts_ongoing-contracts_container"
      data-testid="spendByFacilityContainer"
    >
      <Typography.Body1
        className="compliance-container__chart-wrapper__title"
        weight={TYPOGRAPHY_WEIGHT.BOLD}
      >
        Spend by Facility
      </Typography.Body1>
      <InfiniteList<TLinearChart>
        items={spendByFacility}
        loader={Loader}
        renderItem={Chart}
        loading={false}
        keyExtractor={(item) => item.uuid}
        hasNextPage={loadMoreSpendByFacility.hasNextPage}
        onLoadMore={loadMoreSpendByFacility.handleLoadMore}
        listEmptyComponent={
          <div className="full-width full-height row align-center justify-center empty-state">
            <Typography.Body1>The list is empty</Typography.Body1>
          </div>
        }
        className="chat-list-feature-list"
      />
    </div>
  )
}

export default SpendByFacility
