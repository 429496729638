import { SortOrder } from 'antd/lib/table/interface'
import {
  TPageInfo,
  TQueryParams
} from '../../../../../../../components/Table/types'
import { AxiosResponse, CancelToken } from 'axios'
import { ResponseWithPagination } from '../../../../../../../constants'
import { Dispatch, SetStateAction } from 'react'

export enum DISPUTE_STATUSES {
  EXCLUDED = 'excluded',
  DEFERRED = 'deferred'
}

export enum FACILITY_STATUSES {
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  PENDING = 'pending'
}

export type ActionsProps = {
  selectedRowKeys: string[]
  setSelectedRowKeys: Dispatch<SetStateAction<string[]>>
  updateData: () => void
}

export type InvoicesTableRecord = {
  uuid: string
  document_key: string
  can_dispute: boolean
  supplier_invoice_number: string
  dispute: {
    deferred_date: string
    dispute_reason: string
    dispute_type: DISPUTE_STATUSES
    dispute_type_date: string
    document_key: string
    facility_approval_date: string | null
    facility_approval_reason: string | null
    facility_approval_status: FACILITY_STATUSES
  } | null
  led_by: string
  contract_category_id: string
  contract_category_name: string
  invoice_date: string
  invoice_amount: string
}

export type InvoicesTableFilters = {
  led_by?: string[]
  dispute?: string[]
  facility_status?: string[]
  invoice_date?: string
}

export type InvoicesTableFunc = (
  field: keyof InvoicesTableFilters,
  filters: string[]
) => void

export type InvoicesTableRequestData = {
  sortField?: keyof InvoicesTableRecord
  sortOrder?: SortOrder
  filters?: InvoicesTableFilters
  page?: TPageInfo
}

export type InvoicesTableParams = InvoicesTableFilters & TQueryParams

export type TRebatesTableRequest = (
  id: string,
  data: {
    params?: InvoicesTableParams
    cancelToken?: CancelToken
  }
) => Promise<AxiosResponse<ResponseWithPagination<InvoicesTableRecord>>>

export type InvoicesTableColumnsFunc = {
  id: string
  applyFilters: InvoicesTableFunc
  filters: InvoicesTableFilters
}
