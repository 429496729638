import './styles.scss'
import EmptyState from './EmptyState'
const BankAccount = () => {
  const isActive = false
  return (
    <div className="bank-account-container  full-width full-height">
      {isActive ? <div>sdsdSD</div> : <EmptyState />}
    </div>
  )
}

export default BankAccount
