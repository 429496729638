import { DescriptionCell } from '../../../../../../../../components/DescriptionCell/DescriptionCell'
import {
  Typography,
  TYPOGRAPHY_WEIGHT
} from '../../../../../../../../components/Typography'
import { ReasonCellProps } from './types'
import './styles.scss'

export const ReasonCell = ({
  reason,
  title,
  text,
  trigger = 'click',
  triggerElement
}: ReasonCellProps) => (
  <DescriptionCell
    className="reason-cell"
    trigger={trigger}
    content={
      <div className="column gap-8">
        <Typography.Label
          className="reason-cell__title"
          weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
        >
          {title}
        </Typography.Label>
        {reason}
      </div>
    }
    text={text}
    triggerElement={triggerElement}
  />
)
