import moment from 'moment'
import cn from 'classnames'
import { SortOrder } from 'antd/es/table/interface'
import { ColumnsType } from 'antd/es/table'
import {
  FINANCIAL_STATUS_TYPES_TO_CLASSNAME,
  Status
} from '../../../../components/Status'
import { InvoicesDetailsPopup } from './InvoicesDetailsPopup/InvoicesDetailsPopup'
import { Link } from '../../../../components/Link'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import { Sorter } from '../../../../components/Sorter/Sorter'
import { Tooltip } from '../../../../components/Tooltip/Tooltip'
import { Dropdown } from '../../../../components/Dropdown'
import { currencyFormatter } from '../../../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { getFinancialStatus } from '../constants'
import {
  getHospitalFilterOptions,
  getHSFilterOptions,
  getStatusFilterOptions,
  getVendorFilterOptions
} from './api'
import { ReactComponent as FilterApplied16 } from '../../../../assets/svg/FilterApplied16.svg'
import { ReactComponent as Filter16 } from '../../../../assets/svg/Filter16.svg'
import { ReactComponent as RemitAdvice16 } from 'assets/svg/RemitAdvice16.svg'
import { ReactComponent as QuarterlyReport16 } from 'assets/svg/QuarterlyReport16.svg'
import { timelineDateFormat } from '../../../../components/Timelines/constants'
import { routes } from '../../../../router'
import { ROLES } from '../../../Permission'
import {
  DueDateStatuses,
  TRebatesTableColumnsFunc,
  TRebatesTableFilters,
  TRebatesTableFunc,
  TRebatesTableType
} from './types'

export const columns = ({
  applyFilters,
  filters,
  user
}: TRebatesTableColumnsFunc): ColumnsType<TRebatesTableType> => {
  const isVendor = !!user.vendor
  const isCommons = !!user.commons
  const hideHSName = user.role === ROLES.COMMUNITY_RCA
  const hideInvoices = user.role === ROLES.COMMUNITY_PRESIDENT

  const cols: ColumnsType<TRebatesTableType> = []
  if (!isVendor) {
    cols.push({
      dataIndex: 'supplier_name',
      key: 'supplier_name',
      width: 250,
      filterDropdown: (props) => (
        <Dropdown.Filter<TRebatesTableFilters, TRebatesTableFunc, unknown>
          applyFilters={applyFilters}
          appliedFilters={filters}
          searchCallback={getVendorFilterOptions}
          field="vendors"
          asyncSearch
          {...props}
        />
      ),
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TRebatesTableType>
          dataIndex="supplier_name"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Vendor name
        </Sorter>
      ),
      filterIcon: !!filters?.vendors?.length ? (
        <FilterApplied16 />
      ) : (
        <Filter16 />
      ),
      render: (_, { supplier_name }) =>
        isCommons ? (
          // TODO: complete redirect when response changes
          <Link to={`${routes.myCommunity}${routes.vendors}`}>
            {supplier_name}
          </Link>
        ) : (
          supplier_name
        ),
      sorter: true,
      showSorterTooltip: false
    })
  }
  if (!hideHSName) {
    cols.push({
      dataIndex: 'health_system_name',
      key: 'health_system_name',
      width: 250,
      filterDropdown: (props) => (
        <Dropdown.Filter<TRebatesTableFilters, TRebatesTableFunc, unknown>
          applyFilters={applyFilters}
          appliedFilters={filters}
          searchCallback={getHSFilterOptions}
          field="health_systems"
          asyncSearch
          {...props}
        />
      ),
      filterIcon: !!filters?.health_systems?.length ? (
        <FilterApplied16 />
      ) : (
        <Filter16 />
      ),
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TRebatesTableType>
          dataIndex="health_system_name"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          HS name
        </Sorter>
      ),
      render: (_, { health_system_name }) => health_system_name,
      sorter: true,
      showSorterTooltip: false
    })
  }

  const allColumns = cols.concat([
    {
      dataIndex: 'hospital_name',
      key: 'hospital_name',
      width: 250,
      filterDropdown: (props) => (
        <Dropdown.Filter<TRebatesTableFilters, TRebatesTableFunc, unknown>
          applyFilters={applyFilters}
          appliedFilters={filters}
          searchCallback={getHospitalFilterOptions}
          field="hospitals"
          asyncSearch
          {...props}
        />
      ),
      filterIcon: !!filters?.hospitals?.length ? (
        <FilterApplied16 />
      ) : (
        <Filter16 />
      ),
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TRebatesTableType>
          dataIndex="hospital_name"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Hospital
        </Sorter>
      ),
      render: (_, { hospital_name }) => hospital_name,
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'period_ending',
      key: 'period_ending',
      width: 158,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TRebatesTableType>
          dataIndex="period_ending"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Period ending
        </Sorter>
      ),
      render: (_, { period_ending }) => (
        <span className="separate-item">
          {period_ending
            ? moment(period_ending).format(timelineDateFormat)
            : '-'}
          <br />
        </span>
      ),
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'due_date',
      key: 'due_date',
      width: 158,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TRebatesTableType>
          dataIndex="due_date"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Due date
        </Sorter>
      ),
      render: (_, { due_date, due_date_status }) => (
        <div
          className={cn('td-custom-class', {
            'yellow-cells': due_date_status === DueDateStatuses.DUE_SOON,
            'red-cells': due_date_status === DueDateStatuses.OVERDUE
          })}
        >
          {due_date ? moment(due_date).format(timelineDateFormat) : '-'}
        </div>
      ),
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'invoices',
      key: 'invoices',
      title: 'Invoices',
      width: 220,
      render: (_, row) => (
        <div className="row space-between align-center">
          {!hideInvoices && <InvoicesDetailsPopup invoiceData={row} />}
          <div className="row align-center gap-4">
            {!hideInvoices && (
              <Tooltip title="Download the quarterly rebate report">
                <Button.Icon
                  icon={<QuarterlyReport16 />}
                  type={BUTTON_TYPES.DEFAULT}
                />
              </Tooltip>
            )}
            <Tooltip title="Download the remit advice document">
              <Button.Icon
                icon={<RemitAdvice16 />}
                type={BUTTON_TYPES.DEFAULT}
              />
            </Tooltip>
          </div>
        </div>
      )
    },
    {
      dataIndex: 'total',
      key: 'total',
      width: 145,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TRebatesTableType>
          dataIndex="total"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Total, $
        </Sorter>
      ),
      sorter: true,
      showSorterTooltip: false,
      render: (_, { total }) => currencyFormatter(total)
    },
    {
      dataIndex: 'status',
      key: 'status',
      width: 145,
      filterDropdown: (props) => (
        <Dropdown.Filter<TRebatesTableFilters, TRebatesTableFunc, unknown>
          applyFilters={applyFilters}
          appliedFilters={filters}
          searchCallback={getStatusFilterOptions}
          field="statuses"
          valueField="value"
          labelField="label"
          asyncSearch
          {...props}
        />
      ),
      filterIcon: !!filters?.statuses?.length ? (
        <FilterApplied16 />
      ) : (
        <Filter16 />
      ),
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TRebatesTableType>
          dataIndex="status"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Status
        </Sorter>
      ),
      render: (_, { status }) => (
        <Status.Default className={FINANCIAL_STATUS_TYPES_TO_CLASSNAME[status]}>
          {getFinancialStatus(status, isVendor)}
        </Status.Default>
      ),
      sorter: true,
      showSorterTooltip: false
    }
  ])

  return allColumns
}
