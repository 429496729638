import { useMemo, useState } from 'react'

import './styles.scss'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import { UserListItem } from 'features/ContractDetails/ContractDetailsGeneral/ResponsibleUsers/UserList/UserListItem'
import { ROLES } from '../../../../Permission'
import { Typography } from '../../../../../components/Typography'
import { ReactComponent as Plus16 } from '../../../../../assets/svg/Plus16.svg'
import { Button, BUTTON_TYPES } from '../../../../../components/Button'
import { BTN_TXT } from '../../../../../constants'
import { Reassign } from '../../../../Reassign/ReassignPopup'

type Props = {
  vendor?: boolean
}

const UserList = ({ vendor }: Props) => {
  const contractDetailsContext = useContractDetailsContext()

  const [isOpenReassign, setIsOpenReassign] = useState(false)

  const { details, vendorCanSeeAddTeamMemberButton, isCommons } =
    contractDetailsContext.state

  const { getContractDetailsAsync } = contractDetailsContext.actions

  const responsibleUsers = useMemo(() => {
    if (vendor) {
      const vcs = details?.vendor_responsibles?.find(
        (i) => i.role === ROLES.VENDOR_CONTRACT_STEWARD
      )
      if (!!vcs) {
        const vendorResp =
          details?.vendor_responsibles.filter(
            (i) => i.role !== ROLES.VENDOR_CONTRACT_STEWARD
          ) ?? []
        return [vcs, ...vendorResp]
      } else {
        return [...(details?.vendor_responsibles ?? [])]
      }
    } else {
      return [details.creator]
    }
  }, [details.creator, details?.vendor_responsibles, vendor])

  if (vendor && !details?.vendor_responsibles.length) {
    return vendorCanSeeAddTeamMemberButton ? (
      <>
        <Button
          icon={<Plus16 />}
          type={BUTTON_TYPES.DEFAULT}
          onClick={() => setIsOpenReassign(true)}
          upperCase
        >
          {BTN_TXT.ADD_TEAM_MEMBER}
        </Button>
        <Reassign
          isRFP={false}
          isReassignPopupOpen={isOpenReassign}
          setIsReassignPopupOpen={setIsOpenReassign}
          ownerRole={ROLES.VENDOR_CONTRACT_STEWARD}
          ownerName={''}
          uuid={details?.uuid}
          isCommons={isCommons}
          update={getContractDetailsAsync}
          isAddNew
        />
      </>
    ) : (
      <Typography.Body1>Not yet assigned</Typography.Body1>
    )
  }

  return (
    <div className="rfp-details-responsible__user-list">
      {responsibleUsers.map((user) => (
        <UserListItem
          key={user.uuid}
          user={user}
          isOpenReassign={isOpenReassign}
          setIsOpenReassign={setIsOpenReassign}
        />
      ))}
    </div>
  )
}

export default UserList
