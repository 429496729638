import { Dispatch, SetStateAction } from 'react'

export enum MODAL_TYPES {
  EXCLUDE_FORM_REBATE = 'EXCLUDE_FORM_REBATE',
  DEFER_TO_NEXT_QUARTER = 'DEFER_TO_NEXT_QUARTER',
  DECLINE_DISPUTE = 'DECLINE_DISPUTE'
}

export type ActionReasonPopupProps = {
  onSubmit: (reason: string) => Promise<void>
  setSelectedRowKeys: Dispatch<SetStateAction<string[]>>
  updateData: () => void
}

export type ActionReasonForm = {
  reason: string
}
