import { addBankAccountInitialValues } from './constants'
import { validateMessages } from '../../helper/common'
import cn from 'classnames'
import { Form } from 'antd'
import { useEffect, useState } from 'react'
import { setLoading } from '../../redux/store/common/slice'
import { useDispatch } from 'react-redux'
import {
  TAddBankAccountForm,
  TAddBankAccountFormErrorType,
  TAddBankAccountFormFields,
  TAddBankAccoutProps
} from './forms.addBankAccount.d'
import { Input } from '../Input'
import { LABELS, PLACEHOLDERS } from '../../constants'
import './forms.addBankAccount.scss'
import { Select } from '../Select'

import {
  addBusinessAccount,
  fetchAccountTypeOptions
} from '../../features/PaymentDetails/api'
import { TOption } from '../Select/types'

export const AddBankAccount = ({
  className,
  form,
  data,
  callback,
  setIsActive,
  setIsChanged
}: TAddBankAccoutProps) => {
  const dispatch = useDispatch()
  const [fields, setFields] = useState<TAddBankAccountFormFields | any>([])
  const [accountType, setAccountType] = useState<TOption[]>([])
  const [errors, setErrors] = useState<TAddBankAccountFormErrorType | any>({})

  const getAccountTypes = async () => {
    await fetchAccountTypeOptions().then((res) => {
      setAccountType(
        res.data.results.map((option) => ({
          label: option.value,
          value: option.id
        }))
      )
    })
  }

  const getSelectorsData = async () => {
    dispatch(setLoading(true))
    try {
      getAccountTypes()
    } finally {
      dispatch(setLoading(false))
    }
  }

  useEffect(() => {
    getSelectorsData()
  }, [])

  useEffect(() => {
    if (data?.uuid) {
      const formattedData = {}
      for (const key in data) {
        if (typeof data[key] === 'object' && key === 'address') {
          for (const item in data[key]) {
            formattedData[item] = data[key][item]
          }
        } else {
          formattedData[key] = data[key]
        }
        const fields = Object.keys(formattedData).map((i) => ({
          name: i,
          value: formattedData[i]
        }))
        setFields(fields)
      }
    }
  }, [data?.uuid])

  const handleFinish = (formData: TAddBankAccountForm) => {
    console.log(formData, callback)
    setIsActive(false)
    setIsChanged(false)
    dispatch(setLoading(true))
    addBusinessAccount(data)
      .then(() => {
        console.log(121212)
      })
      .catch((e) => {
        setErrors(e.data)
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }

  return (
    <Form<TAddBankAccountForm>
      form={form}
      onFinish={handleFinish}
      fields={fields}
      initialValues={addBankAccountInitialValues}
      validateMessages={validateMessages}
      onChange={() => {
        setIsActive(true)
        setIsChanged(true)
      }}
      id="add-bank-account-form"
      className={cn('add-bank-account-form', className)}
    >
      <div className="row two-columns">
        <Input
          propsItem={{
            name: 'account_number',
            label: LABELS.ACCOUNT_NUMBER,
            help: errors.account_number && errors.account_number[0],
            validateStatus: errors.account_number && 'error',
            rules: [
              {
                required: true,
                pattern: /^(\d*\.)?\d+$/
              }
            ]
          }}
          propsInput={{ maxLength: 9 }}
        />
        <Input
          propsItem={{
            name: 'confirm_account_number',
            label: LABELS.CONFIRM_ACCOUNT_NUMBER,
            help:
              errors.confirm_account_number && errors.confirm_account_number[0],
            validateStatus: errors.confirm_account_number && 'error',
            rules: [
              {
                required: true,
                pattern: /^(\d*\.)?\d+$/
              }
            ]
          }}
          propsInput={{ maxLength: 9 }}
        />
      </div>
      <div className="row two-columns">
        <Input
          propsItem={{
            name: 'account_number',
            label: LABELS.ROUTING_NUMBER,
            help: errors.routing_number && errors.routing_number[0],
            validateStatus: errors.routing_number && 'error',
            rules: [
              {
                required: true,
                pattern: /^(\d*\.)?\d+$/
              }
            ]
          }}
          propsInput={{ maxLength: 9 }}
        />
        <Input
          propsItem={{
            name: 'confirm_account_number',
            label: LABELS.ACCOUNT_HOLDER_NAME,
            help: errors.account_holder_name && errors.account_holder_name[0],
            validateStatus: errors.account_holder_name && 'error',
            rules: [
              {
                required: true,
                pattern: /^(\d*\.)?\d+$/
              }
            ]
          }}
          propsInput={{ maxLength: 9 }}
        />
      </div>
      <div className="row four-in-row gap-24">
        <Select
          options={accountType}
          propsItem={{
            label: LABELS.ACCOUNT_TYPE,
            name: 'account_type',
            help: errors?.account_type ? errors?.account_type[0] : undefined
          }}
          propsSelect={{
            required: true,
            placeholder: PLACEHOLDERS.PLEASE_SELECT
          }}
        />
      </div>
      <div className="mt-24 row description-row" />
    </Form>
  )
}
