import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Moment } from 'moment'
import moment from 'moment/moment'
import {
  TComplianceParams,
  TGroupByMonth,
  TGroupedLinearChart,
  TLinearChart,
  TMyActivityChart,
  TNonComplianceProducts,
  TOverallCompliance,
  TSpendByVendor
} from '../types'
import {
  fetchMyActivityForVendorData,
  fetchNonCompliantProducts,
  fetchOverallComplianceData,
  fetchSpendByCategory,
  fetchSpendByFacility,
  fetchSpendByMonth,
  fetchSpendByVendorData
} from '../api'
import useInfiniteData, {
  UseInfiniteDataHookResult
} from '../../../hooks/useInfiniteData'
import { TQueryParams } from '../../../components/Table/types'
import { useSelector } from 'react-redux'
import { getUser } from '../../../redux/store/user/getters'

type ContextProps = {
  state: {
    date: Moment | null
    overallCompliance: TOverallCompliance[]
    dataPerMonth: TGroupByMonth[]
    spendByFacility: TLinearChart[]
    spendByCategory: TGroupedLinearChart[]
    myActivity: TMyActivityChart[]
    totalSpend: string
    totalSpendByFacility: number
    loadMoreSpendByFacility: UseInfiniteDataHookResult
    loadMoreNonCompliantProduct: UseInfiniteDataHookResult
    loadMoreSpendByCategory: UseInfiniteDataHookResult
    spendByVendor: TSpendByVendor[]
    nonComplianceProducts: TNonComplianceProducts[]
    showMyActivityChart: boolean
  }
  actions: {
    setDate: (val: Moment | null) => void
    setSelectedCategories: (data: string[]) => void
    setSelectedFacilities: (data: string[]) => void
  }
}
const ComplianceContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const ComplianceContextContextProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const user = useSelector(getUser)
  const [totalSpendByFacility, setTotalSpendByFacility] = useState(0)
  const [totalSpendByCategory, setTotalSpendByCategory] = useState(0)
  const [totalNonComplianceProducts, setTotalNonComplianceProducts] =
    useState(0)
  const [date, setDate] = useState<Moment | null>(null)
  const [overallCompliance, setOverallCompliance] = useState<
    TOverallCompliance[]
  >([])
  const [spendByVendor, setSpendByVendor] = useState<TSpendByVendor[]>([])
  const [myActivity, setMyActivity] = useState<TMyActivityChart[]>([])
  const [totalSpend, setTotalSpend] = useState<string>('0')
  const [dataPerMonth, setDataPerMonth] = useState<TGroupByMonth[]>([])
  const [spendByFacility, setSpendByFacility] = useState<TLinearChart[]>([])
  const [spendByCategory, setSpendByCategory] = useState<TGroupedLinearChart[]>(
    []
  )
  const [nonComplianceProducts, setNonComplianceProducts] = useState<
    TNonComplianceProducts[]
  >([])
  const [selectedCategories, setSelectedCategories] = useState<string[]>([])
  const [selectedFacilities, setSelectedFacilities] = useState<string[]>([])

  const getParams: TComplianceParams = useMemo(() => {
    return {
      period: moment(date).format('YYYY-MM'),
      contract_category: selectedCategories,
      facility: selectedFacilities
    }
  }, [date, selectedCategories, selectedFacilities])

  const getOverallCompliance = useCallback(async () => {
    await fetchOverallComplianceData({ params: getParams })
      .then((resp) => {
        if (resp.data) {
          setOverallCompliance(
            resp.data?.data.map((segment) => ({
              ...segment,
              name: segment.name?.toLowerCase()?.replace('-', '_'),
              amount: segment.amount === null ? 0 : segment.amount
            }))
          )
          setTotalSpend(resp.data.total_spend)
        }
      })
      .catch((e) => console.error(e))
  }, [getParams])

  const getMyActivityForVendor = useCallback(async () => {
    await fetchMyActivityForVendorData({
      params: {
        period: getParams.period,
        facility: getParams.facility
      }
    })
      .then((resp) => {
        if (resp.data) {
          setMyActivity(resp.data?.results)
        }
      })
      .catch((e) => console.error(e))
  }, [getParams])

  const getSpendByVendor = useCallback(async () => {
    await fetchSpendByVendorData({ params: getParams })
      .then((resp) => {
        setSpendByVendor(
          resp.data.data.map((segment) => ({
            ...segment,
            amount: segment.amount === null ? 0 : segment.amount
          }))
        )
      })
      .catch((e) => console.error(e))
  }, [getParams])

  const getNonComplianceProducts = useCallback(async () => {
    await fetchNonCompliantProducts({
      params: { limit: 50, offset: 0, ...getParams }
    })
      .then((resp) => {
        setNonComplianceProducts(resp?.data?.results)
        setTotalNonComplianceProducts(resp?.data?.count)
      })
      .catch((e) => console.error(e))
  }, [getParams])

  const getSpendByFacility = useCallback(async () => {
    await fetchSpendByFacility({
      params: { limit: 50, offset: 0, ...getParams }
    })
      .then((resp) => {
        setSpendByFacility(resp.data?.results)
        setTotalSpendByFacility(resp.data.count)
      })
      .catch((e) => console.error(e))
  }, [getParams])

  const getSpendByCategory = useCallback(async () => {
    await fetchSpendByCategory({
      params: { limit: 50, offset: 0, ...getParams }
    })
      .then((resp) => {
        setSpendByCategory(resp.data?.results)
        setTotalSpendByCategory(resp.data.count)
      })
      .catch((e) => console.error(e))
  }, [getParams])

  const getMoreSpendByFacility = useCallback(
    async (args: TQueryParams) => {
      const resp = await fetchSpendByFacility({
        params: { ...args, ...getParams }
      })
      if (resp.data?.results) {
        setSpendByFacility((prev) => [...prev, ...resp.data.results])
      }
    },
    [getParams]
  )

  const getMoreSpendByCategory = useCallback(
    async (args: TQueryParams) => {
      const resp = await fetchSpendByCategory({
        params: { ...args, ...getParams }
      })
      if (resp.data?.results) {
        setSpendByCategory((prev) => [...prev, ...resp.data.results])
      }
    },
    [getParams]
  )

  const getMoreNonCompliant = useCallback(
    async (args: TQueryParams) => {
      const resp = await fetchNonCompliantProducts({
        params: { ...args, ...getParams }
      })
      if (resp.data?.results) {
        setNonComplianceProducts((prev) => [...prev, ...resp.data.results])
      }
    },
    [getParams]
  )

  const loadMoreSpendByFacility = useInfiniteData({
    total: totalSpendByFacility,
    limit: 50,
    loadMore: getMoreSpendByFacility
  })
  const loadMoreNonCompliantProduct = useInfiniteData({
    total: totalNonComplianceProducts,
    limit: 50,
    loadMore: getMoreNonCompliant
  })
  const loadMoreSpendByCategory = useInfiniteData({
    total: totalSpendByCategory,
    limit: 50,
    loadMore: getMoreSpendByCategory
  })

  const getSpendByMonth = useCallback(async () => {
    await fetchSpendByMonth({
      params: {
        contract_category: selectedCategories,
        facility: selectedFacilities
      }
    })
      .then((resp) => {
        const month =
          resp.data.group_by_month_data[
            resp.data.group_by_month_data.length - 1
          ]?.month
        setDate(moment(month, 'MMM YYYY'))
        setDataPerMonth(resp.data.group_by_month_data)
      })
      .catch((e) => console.error(e))
  }, [selectedCategories, selectedFacilities])
  useEffect(() => {
    getSpendByMonth()
  }, [selectedCategories, selectedFacilities])
  useEffect(() => {
    if (!date) return
    getOverallCompliance()
    getSpendByCategory()
    getSpendByVendor()
    getSpendByFacility()
    getNonComplianceProducts()
    if (showMyActivityChart) getMyActivityForVendor()
  }, [selectedCategories, selectedFacilities, date])

  const showMyActivityChart = !!user.vendor

  const context = useMemo(
    () => ({
      state: {
        date,
        overallCompliance,
        totalSpend,
        dataPerMonth,
        spendByFacility,
        totalSpendByFacility,
        loadMoreSpendByFacility,
        loadMoreSpendByCategory,
        loadMoreNonCompliantProduct,
        spendByVendor,
        nonComplianceProducts,
        spendByCategory,
        showMyActivityChart,
        myActivity
      },
      actions: { setDate, setSelectedFacilities, setSelectedCategories }
    }),
    [
      date,
      setDate,
      overallCompliance,
      totalSpend,
      dataPerMonth,
      spendByFacility,
      setSelectedFacilities,
      setSelectedCategories,
      totalSpendByFacility,
      loadMoreSpendByFacility,
      loadMoreSpendByCategory,
      loadMoreNonCompliantProduct,
      spendByVendor,
      nonComplianceProducts,
      spendByCategory,
      showMyActivityChart,
      myActivity
    ]
  )
  return (
    <ComplianceContext.Provider value={context}>
      {children}
    </ComplianceContext.Provider>
  )
}
export const useComplianceContext = () => useContext(ComplianceContext)

export default ComplianceContextContextProvider
