import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability'
import { IUser } from 'redux/store/user/types'
import { ROLES } from 'features/Permission'
import { ACTIONS, SUBJECTS } from './constants'
import {
  FinancialsAbilityBuilder,
  FinancialsAbility,
  PossibleAbilities,
  Conditions
} from './types'

const ContractAbilityClass = Ability as AbilityClass<FinancialsAbility>

export const ability = new Ability<PossibleAbilities, Conditions>()

export default (user: IUser) => {
  const builder = new AbilityBuilder(ContractAbilityClass)

  switch (user.role) {
    case ROLES.VENDOR_CONTRACT_ADMIN:
    case ROLES.VENDOR_FINANCE_OFFICER:
    case ROLES.VENDOR_ANALYST:
    case ROLES.VENDOR_CONTRACT_STEWARD:
      defineVendorsAbilities(builder)
      break
    case ROLES.COMMONS_VICE_PRESIDENT:
    case ROLES.COMMONS_CONTRACT_STEWARD:
    case ROLES.COMMONS_ANALYST:
      defineCognusCommonsAbilities(builder)
      break
    case ROLES.COMMUNITY_PRESIDENT:
    case ROLES.VICE_PRESIDENT:
    case ROLES.CONTRACT_STEWARD:
    case ROLES.ANALYST:
    case ROLES.COMMUNITY_RCA:
      defineCognusCommunityAbilities(builder, user)
  }

  return new Ability(builder.rules) as FinancialsAbility
}

const defineVendorsAbilities = (builder: FinancialsAbilityBuilder) => {
  builder.can(ACTIONS.VIEW, SUBJECTS.REBATES_DASHBOARD)
  builder.can(ACTIONS.DISPUTE, SUBJECTS.INVOICE)
  builder.can(ACTIONS.SELECT, SUBJECTS.REBATE)
  builder.can(ACTIONS.VIEW, SUBJECTS.INVOICE_DETAILS)
}

const defineCognusCommonsAbilities = (builder: FinancialsAbilityBuilder) => {
  builder.can(ACTIONS.VIEW, SUBJECTS.REBATES_DASHBOARD)
  builder.can(ACTIONS.SELECT, SUBJECTS.REBATE)
  builder.can(ACTIONS.VIEW, SUBJECTS.INVOICE_DETAILS)
}

const defineCognusCommunityAbilities = (
  builder: FinancialsAbilityBuilder,
  user: IUser
) => {
  builder.can(ACTIONS.VIEW, SUBJECTS.REBATES_DASHBOARD)
  if (user.role !== ROLES.COMMUNITY_PRESIDENT) {
    builder.can(ACTIONS.SELECT, SUBJECTS.REBATE)
    builder.can(ACTIONS.VIEW, SUBJECTS.INVOICE_DETAILS)
    builder.can(ACTIONS.DISPUTE, SUBJECTS.INVOICE)
  }
}
