import { TFormFaCodeForm } from './forms.2FACode.d'
import { TFormFaNumberForm } from './forms.2FANumber.d'
import { HEALTH_SYSTEM_STATUSES } from '../../constants'
import { TAddEditHealthSystemForm } from './forms.addEditHealthSystem.d'
import { TAddEditHospitalForm } from './forms.addEditHospital.d'
import { TFormContactUsForm } from './forms.contactUs.d'
import { TPurchaseOrdersForm } from './forms.editPurchaseOrder.d'
import { TFormForgotPasswordForm } from './forms.forgotPassword.d'
import { TFormSignInForm } from './forms.signIn.d'
import { TFormSignUpForm } from './forms.signUp.d'
import { TFormContactUsLandingForm } from './forms.contactUsLanding.d'
import { TAddBusinessDetailsForm } from './forms.addBusinessDetails.d'
import { TAddBankAccountForm } from './forms.addBankAccount.d'

export const faCodeInitialValues: TFormFaCodeForm = {
  sms_code: '',
  token: ''
}

export const faNumberInitialValues: TFormFaNumberForm = {
  phone_number: ''
}

export const healthSystemsInitialValues: TAddEditHealthSystemForm = {
  name: '',
  email: '',
  state: '',
  tax_id: '',
  phone_number: '',
  zip_code: '',
  city: '',
  street: '',
  person_first_name: '',
  person_last_name: '',
  person_position: '',
  person_email: '',
  person_phone_number: '',
  status: HEALTH_SYSTEM_STATUSES.ACTIVE,
  vice_president: undefined,
  contract_steward: [],
  legal: [],
  analyst: []
}

export const hospitalInitialValues: TAddEditHospitalForm = {
  name: '',
  email: '',
  state: '',
  tax_id: '',
  phone_number: '',
  zip_code: '',
  city: '',
  street: '',
  departments: []
}

export const contactUsInitialValues: TFormContactUsForm = {
  email: '',
  message: ''
}

export const contactUsLandingInitialValues: TFormContactUsLandingForm = {
  email: '',
  first_name: '',
  phone_number: '',
  organization_name: '',
  text: '',
  last_name: ''
}

export const purchaseOrderInitialValues: TPurchaseOrdersForm = {
  quantity: '',
  price: '',
  total_cost: '',
  order_date: '',
  is_disabled: false,
  uuid: '',
  contract_number: ''
}

export const forgotPasswordInitialValues: TFormForgotPasswordForm = {
  email: ''
}

export const signInInitialValues: TFormSignInForm = {
  email: '',
  password: ''
}

export const signUpInitialValues: TFormSignUpForm = {
  password: '',
  repeatPassword: '',
  agreement: false
}

export const addBusinessDetailsInitialValues: TAddBusinessDetailsForm = {
  legal_name: '',
  country: null,
  address_type: null,
  state: null,
  city: '',
  zip_code: '',
  address: '',
  tax_id: '',
  tax_id_type: null,
  description: '',
  incorporation_date: null
}

export const addBankAccountInitialValues: TAddBankAccountForm = {
  account_number: '',
  confirm_account_number: '',
  routing_number: '',
  account_holder_name: '',
  account_type: null
}
