import { HEALTH_SYSTEM_STATUSES } from '../../constants'

export const STATUS_TYPES_CLASSNAMES = {
  GRAY: 'status-default--gray',
  YELLOW: 'status-default--yellow',
  RED: 'status-default--red',
  GREEN: 'status-default--green',
  LIGHTBLUE: 'status-default--lightblue',
  DARKBLUE: 'status-default--darkblue',
  BLACK: 'status-default--black',
  DARKGRAY: 'status-default--darkgray',
  ORANGE: 'status-default--orange',
  LIGHTRED: 'status-default--light-red'
}

export const USER_STATUS_TYPES_TO_CLASSNAME = {
  Active: STATUS_TYPES_CLASSNAMES.GREEN,
  Invited: STATUS_TYPES_CLASSNAMES.YELLOW,
  Archived: STATUS_TYPES_CLASSNAMES.LIGHTRED,
  Pending: STATUS_TYPES_CLASSNAMES.GRAY
}
export const TRIAL_APPROVAL_TYPES_TO_CLASSNAME = {
  approved: STATUS_TYPES_CLASSNAMES.GREEN,
  'no response': STATUS_TYPES_CLASSNAMES.GRAY,
  rejected: STATUS_TYPES_CLASSNAMES.LIGHTRED
}
export const TRIAL_APPROVAL_LABEL = {
  approved: 'Approved',
  'no response': 'No response',
  rejected: 'Rejected'
}

export const FINANCIAL_STATUS_TYPES_TO_CLASSNAME = {
  sent: STATUS_TYPES_CLASSNAMES.LIGHTBLUE,
  waiting_for_response: STATUS_TYPES_CLASSNAMES.LIGHTBLUE,
  pending_for_payment: STATUS_TYPES_CLASSNAMES.YELLOW,
  payment_in_progress: STATUS_TYPES_CLASSNAMES.GRAY,
  declined: STATUS_TYPES_CLASSNAMES.LIGHTRED,
  payment_failed: STATUS_TYPES_CLASSNAMES.RED,
  disputed: STATUS_TYPES_CLASSNAMES.LIGHTRED,
  paid: STATUS_TYPES_CLASSNAMES.GREEN,
  past_due: STATUS_TYPES_CLASSNAMES.ORANGE
}

export const CONTARCT_STATUS_TYPES_TO_CLASSNAME = {
  active: STATUS_TYPES_CLASSNAMES.GREEN,
  out_for_signature: STATUS_TYPES_CLASSNAMES.YELLOW,
  terminated: STATUS_TYPES_CLASSNAMES.LIGHTRED,
  declined: STATUS_TYPES_CLASSNAMES.LIGHTRED,
  contracting: STATUS_TYPES_CLASSNAMES.GRAY,
  pending_for_start: STATUS_TYPES_CLASSNAMES.LIGHTBLUE,
  expired: STATUS_TYPES_CLASSNAMES.BLACK
}

export const HOSPITAL_STATUS_TO_CLASSNAME = {
  Active: STATUS_TYPES_CLASSNAMES.GREEN,
  Archived: STATUS_TYPES_CLASSNAMES.LIGHTRED
}

export const OPPORTUNITY_ANALYSIS_STATUSES_TO_CLASSNAME = {
  in_review: STATUS_TYPES_CLASSNAMES.GRAY,
  accepted: STATUS_TYPES_CLASSNAMES.GREEN,
  terminated: STATUS_TYPES_CLASSNAMES.LIGHTRED
}

export const CONTARCT_PIPELINES_STATUS_TYPES_TO_CLASSNAME = {
  active: STATUS_TYPES_CLASSNAMES.GREEN,
  future: STATUS_TYPES_CLASSNAMES.GRAY,
  in_process: STATUS_TYPES_CLASSNAMES.DARKBLUE
}

export const CONTRACT_PIPELINES_STATUS = {
  ACTIVE: 'active',
  IN_PROCESS: 'in_process',
  FUTURE: 'future'
} as const

export const CONTRACT_PIPELINES_STATUS_NAME = {
  [CONTRACT_PIPELINES_STATUS.ACTIVE]: 'Active',
  [CONTRACT_PIPELINES_STATUS.IN_PROCESS]: 'In-process',
  [CONTRACT_PIPELINES_STATUS.FUTURE]: 'Future'
} as const

export const HEALTH_SYSTEM_STATUSES_CLASSNAME = {
  [HEALTH_SYSTEM_STATUSES.ACTIVE]: STATUS_TYPES_CLASSNAMES.GREEN,
  [HEALTH_SYSTEM_STATUSES.ARCHIVED]: STATUS_TYPES_CLASSNAMES.LIGHTRED
} as const

export const DISPUTE_STATUSES_CLASSNAME = {
  excluded: STATUS_TYPES_CLASSNAMES.LIGHTBLUE,
  deferred: STATUS_TYPES_CLASSNAMES.LIGHTRED
}
