import Typography from 'components/Typography/Typography'
import { ReactComponent as HospitalIcon } from 'assets/svg/Hospital.svg'
import { Button, BUTTON_TYPES } from 'components/Button'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { BTN_TXT } from '../../../constants'
import { useNavigate } from 'react-router'
import { routes } from '../../../router'
import './styles.scss'

const EmptyState = () => {
  const navigate = useNavigate()
  const handleRedirect = () => navigate(routes.addBankAccount)
  return (
    <div className="bank-account-container__empty-state row justify-center">
      <div className="bank-account-container__empty-state__wrapper column align-center gap-24">
        <HospitalIcon />
        <Typography.Headline6 className="mt-16">
          No Bank account added yet.
        </Typography.Headline6>
        <Button
          className="contract-categories__add-btn"
          onClick={handleRedirect}
          icon={<Plus16 />}
          type={BUTTON_TYPES.PRIMARY}
          upperCase
          medium
        >
          {BTN_TXT.ADD_BANK_ACCOUNT}
        </Button>
      </div>
    </div>
  )
}

export default EmptyState
