import { IUser } from 'redux/store/user/types'
import { ROLES } from '../Permission'
import {
  getBusinessDetailsCommunity,
  getBusinessDetailsHealthSystem
} from './api'
import moment from 'moment/moment'
import {
  TAddBusinessDetails,
  TAddBusinessDetailsForm
} from './BusinessDetails/types'

export const getRequestForOrumDetails = (user: IUser) => {
  switch (user.role) {
    case ROLES.COMMUNITY_PRESIDENT:
      return getBusinessDetailsCommunity
    case ROLES.VICE_PRESIDENT:
    case ROLES.COMMUNITY_RCA:
      return getBusinessDetailsHealthSystem
    default:
      return null
  }
}
export const getIdForOrumDetails = (user: IUser) => {
  switch (user.role) {
    case ROLES.COMMUNITY_PRESIDENT:
      return ''
    case ROLES.VICE_PRESIDENT:
    case ROLES.COMMUNITY_RCA:
      return user.health_system
    default:
      return ''
  }
}

export const convertDetailsToForm = (
  details: TAddBusinessDetails
): TAddBusinessDetailsForm => ({
  ...details,
  incorporation_date: details.incorporation_date
    ? moment(details.incorporation_date)
    : null
})
