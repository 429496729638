import './styles.scss'
import RadioGroup from '../../components/RadioGroup/RadioGroup'
import {
  addBusinessDetailsInitialValues,
  PAYMENT_DETAILS_OPTIONS
} from './constants'
import { useCallback, useEffect, useState } from 'react'
import BusinessDetails from './BusinessDetails/BusinessDetails'
import BankAccount from './BankAccount/BankAccount'
import { TAddBusinessDetails } from './BusinessDetails/types'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from '../../redux/store/common/slice'
import { getUser } from '../../redux/store/user/getters'
import { getIdForOrumDetails, getRequestForOrumDetails } from './utils'

const PaymentDetails = () => {
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const [activeSubTab, setActiveSubTab] = useState(
    PAYMENT_DETAILS_OPTIONS[0].value
  )
  const [businessDetails, setBusinessDetails] = useState<TAddBusinessDetails>(
    addBusinessDetailsInitialValues
  )
  const handleTabs = (e) => {
    setActiveSubTab(e)
  }

  const getBusinessDetails = useCallback(async () => {
    const request = getRequestForOrumDetails(user)
    const uuid = getIdForOrumDetails(user)
    if (!request) return
    try {
      dispatch(setLoading(true))
      const res = await request(uuid)
      setBusinessDetails(res?.data)
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [user.role])

  useEffect(() => {
    getBusinessDetails()
  }, [])
  return (
    <div className="payment-details-container">
      <div className="mb-24">
        <RadioGroup.Buttons
          options={PAYMENT_DETAILS_OPTIONS}
          defaultValue={activeSubTab}
          onChange={handleTabs}
        />
      </div>
      {!activeSubTab && <BusinessDetails businessDetails={businessDetails} />}
      {activeSubTab === PAYMENT_DETAILS_OPTIONS[0].value && (
        <BusinessDetails businessDetails={businessDetails} />
      )}
      {activeSubTab === PAYMENT_DETAILS_OPTIONS[1].value && <BankAccount />}
    </div>
  )
}
export default PaymentDetails
