import { Container } from '../../components/Container/Container'
import { ADD_BANK_ACCOUNT_BREADCRUMBS } from './constants'
import { Forms } from '../../components/Forms'
import { FloatFooter } from '../../components/FloatFooter/FloatFooter'
import { BTN_TXT } from '../../constants'
import { Form } from 'antd'
import { useCallback, useState } from 'react'
import { addBankAccountInitialValues } from '../../components/Forms/constants'

const AddBankAccount = () => {
  const [form] = Form.useForm()
  const [isActive, setIsActive] = useState(false)
  const [isChanged, setIsChanged] = useState(false)

  const submitCancellation = () => {
    handleCancelForm()
    setIsChanged(false)
  }

  const handleCancelForm = useCallback(() => {
    setIsActive(false)
    form.setFieldsValue(addBankAccountInitialValues)
    history.back()
  }, [form, setIsActive])
  return (
    <Container
      title={'Add Bank account'}
      breadcrumbs={ADD_BANK_ACCOUNT_BREADCRUMBS}
    >
      <div>
        <Forms.AddBankAccount
          form={form}
          callback={() => {
            form.resetFields()
          }}
          setIsActive={setIsActive}
          setIsChanged={setIsChanged}
        />
      </div>
      <FloatFooter
        isActive={isActive}
        onCancel={submitCancellation}
        onSave={form.submit}
        isSaveDisabled={!isChanged}
        saveBtnText={BTN_TXT.ADD}
      />
    </Container>
  )
}

export default AddBankAccount
