import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios, { CancelTokenSource } from 'axios'
import { Table } from '../../../../../../../components/Table'
import { InvoicesTableHeader } from './InvoicesTableHeader/InvoicesTableHeader'
import { columns } from './constants'
import { setLoading } from '../../../../../../../redux/store/common/slice'
import { getUser } from '../../../../../../../redux/store/user/getters'
import { getInvoicesTableData } from './api'
import { useFinancialsContext } from '../../../../../Providers/FinancialsProvider'
import { DEFAULT_PAGE } from '../../../../../../../components/Table/constants'
import {
  InvoicesTableFilters,
  InvoicesTableRequestData,
  InvoicesTableParams,
  InvoicesTableRecord,
  InvoicesTableFunc
} from './types'
import './styles.scss'

export const InvoicesTable = () => {
  const [tableData, setTableData] = useState<InvoicesTableRecord[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
  const [sortParams, setSortParams] = useState<InvoicesTableRequestData>()
  const [firstLoad, setFirstLoad] = useState(true)
  const [filters, setFilters] = useState<InvoicesTableFilters>({})
  const [totalNumber, setTotalNumber] = useState(0)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })

  const tableDataTokenRef = useRef<CancelTokenSource>()

  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const isVendor = !!user?.vendor

  const {
    state: { id }
  } = useFinancialsContext()

  useEffect(() => {
    if (id) {
      getTableData({ ...sortParams })
    }
  }, [id])

  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
    }
  }, [])

  const getTableData = ({
    sortField,
    sortOrder,
    filters,
    page
  }: InvoicesTableRequestData) => {
    dispatch(setLoading(true))
    if (!!tableDataTokenRef.current) {
      tableDataTokenRef.current.cancel()
    }
    const params: InvoicesTableParams = { ...filters }
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    tableDataTokenRef.current = axios.CancelToken.source()
    getInvoicesTableData(id, {
      cancelToken: tableDataTokenRef.current?.token,
      params
    })
      .then((resp) => {
        if (!resp.data?.results) {
          return
        }
        setTableData(
          resp.data.results.map((i) => ({
            ...i,
            key: i.document_key
          }))
        )
        setTotalNumber(resp.data.count)
        if (firstLoad) {
          setFirstLoad(false)
        }
      })
      .finally(() => dispatch(setLoading(false)))
  }

  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters,
      page
    })
    setPageInfo(page)
  }

  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      filters,
      page
    })
    setPageInfo(page)
  }

  const handleSelectRow = (selectedRowKeys) =>
    setSelectedRowKeys(selectedRowKeys)

  const applyFilters: InvoicesTableFunc = (field, appliedFilters) => {
    const newFilters = { ...filters, [field]: appliedFilters }
    const newPageInfo = { ...pageInfo, pageNumber: 1 }
    setFilters(newFilters)
    setPageInfo(newPageInfo)
    getTableData({ ...sortParams, filters: newFilters, page: newPageInfo })
  }

  const updateData = () => getTableData({ ...sortParams, ...filters })

  return (
    <div className="invoices-table-wrapper column gap-4">
      {!!selectedRowKeys.length && (
        <InvoicesTableHeader
          totalNumber={totalNumber}
          selectedRowKeys={selectedRowKeys}
          updateData={updateData}
          setSelectedRowKeys={setSelectedRowKeys}
          currentPage={pageInfo.pageNumber}
          pageSize={pageInfo.pageSize}
        />
      )}
      {firstLoad ? null : (
        <Table<InvoicesTableRecord>
          dataSource={tableData}
          columns={columns({ id, filters, applyFilters })}
          className="invoices-table"
          onChange={handleTableChange}
          onChangePage={handleChangePageSize}
          pageSize={pageInfo.pageSize}
          pagination={{
            pageSize: pageInfo.pageSize,
            current: pageInfo.pageNumber,
            total: totalNumber
          }}
          rowSelection={{
            selectedRowKeys,
            onChange: handleSelectRow,
            getCheckboxProps: (record) => ({
              disabled: isVendor ? !record.can_dispute : !record.dispute
            })
          }}
        />
      )}
    </div>
  )
}
