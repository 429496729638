import { useCallback } from 'react'
import { Button } from '../../../../../../../../components/Button'
import { ActionReasonPopup } from '../ActionReasonPopup/ActionReasonPopup'
import { useFinancialsActionReasonPopup } from '../../../../../../Providers/FinancialsActionReasonPopupProvider'
import { useFinancialsContext } from '../../../../../../Providers/FinancialsProvider'
import { createDispute } from './api'
import { BTN_TXT } from '../../../../../../../../constants'
import { MODAL_TYPE_TO_DISPUTE } from '../ActionReasonPopup/constants'
import { MODAL_TYPES } from '../ActionReasonPopup/types'
import { ActionsProps } from '../types'

export const VendorActions = ({
  selectedRowKeys,
  setSelectedRowKeys,
  updateData
}: ActionsProps) => {
  const {
    state: { type },
    actions: { onOpenPopup }
  } = useFinancialsActionReasonPopup()
  const {
    state: { id }
  } = useFinancialsContext()

  const onSetDisputeStatus = useCallback(
    async (reason: string) => {
      if (type) {
        try {
          await createDispute(id, {
            dispute_reason: reason,
            dispute_type: MODAL_TYPE_TO_DISPUTE[type],
            document_keys: selectedRowKeys
          })
        } finally {
        }
      }
    },
    [id, selectedRowKeys, type]
  )

  return (
    <>
      <Button
        onClick={() => onOpenPopup(MODAL_TYPES.EXCLUDE_FORM_REBATE)}
        danger
        upperCase
      >
        {BTN_TXT.EXCLUDE}
      </Button>
      <Button
        onClick={() => onOpenPopup(MODAL_TYPES.DEFER_TO_NEXT_QUARTER)}
        upperCase
      >
        {BTN_TXT.DEFER_TO_NEXT_QUARTER}
      </Button>
      <ActionReasonPopup
        onSubmit={onSetDisputeStatus}
        setSelectedRowKeys={setSelectedRowKeys}
        updateData={updateData}
      />
    </>
  )
}
